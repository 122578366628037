 import {
     CHECKOUT_SHOPPING_CART, ADD_ITEM_SHOPPING_CART,UPDATE_ITEM_SHOPPING_CART,DELETE_ITEM_SHOPPING_CART, USER_LOGOUT }  from "actions/actionTypes";
 import {REHYDRATE} from 'redux-persist/constants'

var _ = require('lodash');

 const initialState = {  items: []}; /*{_id:'1', Type:'Panel', ReorderPrice:50.00, Description:'Description 1', Quantity:1}*/
export default function (state = initialState, action) {
    switch (action.type) {
        case REHYDRATE: /* this is required to populate reducer state from data saved in persisted store */
            if (_.isUndefined(action.payload.shoppingCartReducer)){
                return   state;
            } else {
                return action.payload.shoppingCartReducer
            }
        case ADD_ITEM_SHOPPING_CART:
            console.log('ADD_ITEM_SHOPPING_CART', state, action.payload);
            var itemIndex = _.findIndex(state.items, {'_id': action.payload._id, ReorderPrice:action.payload.ReorderPrice});
            console.log('itemindex', itemIndex );
            if (itemIndex == -1) {
                return {
                    ...state,
                    items: _.concat(state.items, [action.payload]),
                }
            } else{

                return {
                    ...state,
                    items: [...state.items.slice(0, itemIndex),
                        {...state.items[itemIndex], Quantity: state.items[itemIndex].Quantity + 1},
                        ...state.items.slice(itemIndex + 1)]
                }
            }
        case UPDATE_ITEM_SHOPPING_CART:
            console.log('UPDATE_ITEM_SHOPPING_CART', state, action.payload);
            var itemIndex = _.findIndex(state.items, {'_id': action.payload._id,'ReorderPrice':action.payload.ReorderPrice});

            var updatedRecords = [...state.items.slice(0, itemIndex),
                action.payload,
                ...state.items.slice(itemIndex + 1)];
            return {
                ...state,
                items: updatedRecords
            }
        case DELETE_ITEM_SHOPPING_CART:
            console.log('DELETE_ITEM_SHOPPING_CART', state, action.payload);
            var itemIndex = _.findIndex(state.items, {'_id': action.payload._id,'ReorderPrice':action.payload.ReorderPrice});

            if (itemIndex == -1){
                return state;
            }
            return {
                ...state,
                items: [...state.items.slice(0, itemIndex),
                    ...state.items.slice(itemIndex + 1)]
            }
        case CHECKOUT_SHOPPING_CART:
            console.log('CHECKOUT_SHOPPING_CART', state, action.payload);
              return initialState;
        case USER_LOGOUT:
            console.log('in USER_LOGOUT ' );
            return initialState;

        default:
            return state;
    }
}
