export const USER_LOGOUT = 'USER_LOGOUT';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const FETCH_USER = 'FETCH_USER';
export const FETCH_USERS = 'FETCH_USERS';
export const DELETE_USER = 'DELETE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const ADD_USER = 'ADD_USER';
export const UPDATE_APP_DATA = 'UPDATE_APP_DATA';



/* accounts */
export const ACCOUNT_FETCH_ORDERS = 'ACCOUNT_FETCH_ORDERS';
export const ACCOUNT_UPDATE_ORDER = 'ACCOUNT_UPDATE_ORDER';

export const FETCH_INVENTORIES = 'FETCH_INVENTORIES';
export const FETCH_INVENTORY = 'FETCH_INVENTORY';
export const ADD_INVENTORY = 'ADD_INVENTORY';
export const FETCH_INVENTORIES_SERIALS = 'FETCH_INVENTORIES_SERIALS';
export const CLEAR_INVENTORY_SERIALS = 'CLEAR_INVENTORY_SERIALS';

export const ACCOUNT_ADD_ORDER = 'ACCOUNT_ADD_ORDER';
export const FETCH_ALL_ACCOUNTS = 'FETCH_ALL_ACCOUNTS';

export const ADD_ITEM_SHOPPING_CART = 'ADD_ITEM_SHOPPING_CART';
export const UPDATE_ITEM_SHOPPING_CART = 'UPDATE_ITEM_SHOPPING_CART';


export const DELETE_ITEM_SHOPPING_CART = 'DELETE_ITEM_SHOPPING_CART';
export const CHECKOUT_SHOPPING_CART = 'CHECKOUT_SHOPPING_CART';

 export const UPDATE_ACCOUNT_DATA = 'UPDATE_ACCOUNT_DATA';

export const UPDATE_ACCOUNT_TO_EDIT = 'UPDATE_ACCOUNT_TO_EDIT';

/*admins*/

export const FETCH_ALL_INVOICES = 'FETCH_ALL_INVOICES';
export const FETCH_DEFAULT_PRICING = 'FETCH_DEFAULT_PRICING';
export const UPDATE_DEFAULT_PRICING = 'UPDATE_DEFAULT_PRICING';


export const FETCH_ACCOUNT_PRICING = 'FETCH_ACCOUNT_PRICING';
export const UPDATE_ACCOUNT_PRICING = 'UPDATE_ACCOUNT_PRICING';
 export const ADD_ACCOUNT_PRICING = 'ADD_ACCOUNT_PRICING';
export const DELETE_ACCOUNT_PRICING = 'DELETE_ACCOUNT_PRICING';

export const FETCH_ADMINISTRATOR_USERS = 'FETCH_ADMINISTRATOR_USERS';




export const FETCH_ORDERS = 'FETCH_ORDERS';
export const UPDATE_ORDER = 'UPDATE_ORDER';