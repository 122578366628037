import {FETCH_ADMINISTRATOR_USERS}  from "actions/actionTypes";

var _ = require('lodash');

const initialState = {fetched: false, data: [], total:0};
export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ADMINISTRATOR_USERS:
      console.log('FETCH_ADMINISTRATOR_USERS', action, action.payload);
      return {
        ...state,
        data: action.payload.data,
        total: action.payload.total,
        fetched: true
      };
    default:
      return state;
  }
}