import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {injectStripe, CardCVCElement, CardExpiryElement, CardNumberElement} from 'react-stripe-elements';
//var stripe = Stripe('pk_test_MluOkiv01JGIL0joXBuq8KyD');
import {makeRequest, displayMessage, dispatchAction} from 'actions/index';
import "./../../../theme/css/react-credit-cards.scss";
import {UPDATE_USER_DATA, UPDATE_ACCOUNT_DATA} from "actions/actionTypes";
import {Button, FormGroup, Row, Col, Form, FormControl, ControlLabel} from 'react-bootstrap';


class  AccountBillingSettingsPage extends Component {

    constructor(props, context) {
        super(props, context);
        console.log('inAccountBillingSettingsPage '    );
        this.state = {
            displayAddCardForm: false,
            cardDataFetched: this.props.setupAccountFlow,
            name: '',
            CardNumberIsValid: false,
            ExpirationDateIsValid: false,
            CVCIsValid: false,
        };
    }

    componentWillMount() {
        if (this.props.setupAccountFlow ) {  /*  this is initial account setup */
            if (!this.props.accountDataReducer.fetched) {
                var accountDataParams = {};
                this.props.makeRequest(accountDataParams,  {
                    "success_event": UPDATE_ACCOUNT_DATA,
                    'endpoint': 'accounts/get_account_data',
                })
            }
        } else   {
            var params = {}
            if ( !_.isUndefined( this.props.User.user.AdministratorLoggedInAsCustomer)){
                params.AccountID= this.props.User.user.AccountID
            }
            this.props.makeRequest(params,  {
                'endpoint': 'stripe/customers/card',
            }).then(res => {
                var dataToUpdate = {cardDataFetched: true}
                if (res.data.cardData) {
                    dataToUpdate.name = _.get(res.data, ['CardHolderName'], '')
                    dataToUpdate.existingCardHolderName = _.get(res.data, ['CardHolderName'], '')
                }
                this.setState(dataToUpdate)
            })
        }
    }

    handleInputChange = (value, field) => {
        if (field == 'number') {
            this.setState({CardNumberIsValid: value.complete})
        } else if (field == 'expiry') {
            this.setState({ExpirationDateIsValid: value.complete})
        } else if (field == 'cvc') {
            this.setState({CVCIsValid: value.complete})
        }
    };

    resetFields() {
        this.cardElementRef.clear();
        this.cvcElementRef.clear();
        this.expiryElementRef.clear();
         this.setState({name: '',displayAddCardForm:false})
    }


    saveRecord() {
         this.props.stripe.createToken({type: 'card', name: this.state.name})
            .then(payload => {
                console.log('payloud', payload);
                if (payload.error) {
                    this.props.displayMessage({
                        'messageType': 'warning',
                        'messageCode': payload.error.message
                    });
                } else {
                    var formData = {
                        id: this.props.accountDataReducer.accountData._id,
                        StripeToken: payload.token,
                        CreditCard: {
                            CardExpiryYear: _.get(payload, ['token', 'card', 'exp_year'], undefined),
                            CardExpiryMonth: _.get(payload, ['token', 'card', 'exp_month'], undefined),
                            CardLastFourDigits: _.get(payload, ['token', 'card', 'last4'], undefined),
                         }
                    }
                    var methodData = {
                        "endpoint": '/accounts',
                        type: "patch",
                        messageData: {'#1#': 'Billing data'},
                        messageCode: 'recordUpdated'
                    };
                    if (this.props.professionalBillingDetails){
                        methodData.endpoint= '/agents'
                        formData.id = _.get(this.props.accountDataReducer, ['agentData','_id'], '')
                    }
                    this.props.makeRequest(formData, methodData).then(res => {
                        console.log('in update success', this);
                        if (this.props.professionalBillingDetails){
                            this.props.dispatchAction({agentData: res.data}, UPDATE_ACCOUNT_DATA);
                        }else {
                            this.props.dispatchAction({accountData: res.data}, UPDATE_ACCOUNT_DATA);
                        }
                        if (this.props.handleSuccessDataSubmit) {
                            this.props.handleSuccessDataSubmit();
                        }
                        this.setState({
                            existingCardHolderName:this.state.name,
                             displayAddCardForm:false,
                        })
                    }).catch(err => {

                    });
                }
            })
            .catch(function (error) {
                console.log('error', error);
            });
    }


    render() {
        //console.log('Account Billing  state', this.state);
         if (!this.props.accountDataReducer.fetched || !this.state.cardDataFetched) {
            return null
        }

          var CardLastFourDigits = _.get(this.props.accountDataReducer, [this.props.professionalBillingDetails ? 'agentData': 'accountData', 'CreditCard', 'CardLastFourDigits'], false)
          var CardExpiryMonth = _.get(this.props.accountDataReducer, [this.props.professionalBillingDetails ? 'agentData': 'accountData','CreditCard', 'CardExpiryMonth'], '')
          CardExpiryMonth =  CardExpiryMonth.length == 1 ? '0' + CardExpiryMonth : CardExpiryMonth
        return (
            <div className="top20">
                {!this.state.displayAddCardForm && CardLastFourDigits ?
                    <div>
                        <Row className="bot20">
                            <Col sm={6} xs={12}>
                                <ControlLabel className="">Credit card number:</ControlLabel>
                                <ControlLabel className="left20">
                                    <span className="right4">.</span><span className="right4">.</span><span
                                    className="right4">.</span><span className="right4">.</span>
                                    <span className="left8 right4">.</span><span className="right4">.</span><span
                                    className="right4">.</span><span className="right4">.</span>
                                    <span className="left8 right4">.</span><span className="right4">.</span><span
                                    className="right4">.</span><span
                                    className="right12">.</span>{CardLastFourDigits}
                                </ControlLabel>
                            </Col>
                            <Col sm={6} xs={12}>
                                <ControlLabel className="">Expiry date:</ControlLabel>
                                <ControlLabel className="left20">
                                    <span
                                        className="right4">{CardExpiryMonth}</span>/<span
                                    className="left4">{_.get(this.props.accountDataReducer, [this.props.professionalBillingDetails ? 'agentData': 'accountData','CreditCard',  'CardExpiryYear'], '')}</span>
                                </ControlLabel>
                            </Col>
                        </Row>
                        <Row className="ptop20 top20">
                            <Col sm={6} xs={12}>
                                <ControlLabel className="">Card Holder Name:</ControlLabel>
                                <ControlLabel className="left20">
                                    { this.state.existingCardHolderName}
                                </ControlLabel>
                            </Col>
                            <Col sm={6} xs={12}>
                                <Button onClick={() => this.setState({
                                    name: this.state.existingCardHolderName,
                                    displayAddCardForm: true
                                })}
                                        className="pull-left btn btn-primary" type="button">
                                    Edit
                                </Button>
                            </Col>
                        </Row>


                    </div> :
                    <div>
                        <h4 className="top20 pbot10 bold">Enter the credit card that will be used for valet
                            orders:</h4>
                        <h5 className="bot20">(A credit card is required for use of the valet system)</h5>
                        <Row  >
                            <Col lg={8} md={10} sm={10} xs={12} className=" top10">
                                <Row className="top20">
                                    <Col lg={7} md={6} sm={6} xs={12}>
                                        <FormGroup  >
                                            <ControlLabel className="gray">Credit card number:</ControlLabel>
                                            <CardNumberElement
                                                elementRef={(StripeElement) => {
                                                    this.cardElementRef = StripeElement;
                                                }}
                                                className="form-control"
                                                placeholder=""
                                                onChange={(value) => this.handleInputChange(value, 'number')}
                                                //  onFocus={this.handleInputFocus.bind(this, 'number')}
                                            />

                                        </FormGroup>
                                    </Col>
                                    <Col lg={5} md={6} sm={6} xs={12} className="">
                                        <FormGroup className="">
                                            <ControlLabel className="gray">Expires</ControlLabel>
                                            <CardExpiryElement
                                                className="form-control"
                                                elementRef={(StripeElement) => {
                                                    this.expiryElementRef = StripeElement;
                                                }}
                                                placeholder=""
                                                onChange={(value) => this.handleInputChange(value, 'expiry')}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="top10 bot20">
                                    <Col lg={7} md={6} sm={6} xs={12}>
                                        <FormGroup  >
                                            <ControlLabel className="gray">Name on credit card:</ControlLabel>
                                            <FormControl type="text"
                                                         value={this.state.name}
                                                         name="name"
                                                         onChange={(e) => this.setState({name: e.target.value})}
                                                         placeholder=""/>
                                        </FormGroup>

                                    </Col>
                                    <Col lg={5} md={6} sm={6} xs={12} className="">
                                        <FormGroup  >
                                            <ControlLabel className=" gray">CVC</ControlLabel>
                                            <CardCVCElement
                                                className="form-control"
                                                elementRef={(StripeElement) => {
                                                    this.cvcElementRef = StripeElement;
                                                }}

                                                placeholder=""
                                                onChange={(value) => this.handleInputChange(value, 'cvc')}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={8} md={10} sm={10} xs={12}  className=" top10">
                                <Row>
                                    <Col   xs={12} className="bot20">
                                        <FormGroup>
                                            <Button onClick={this.saveRecord.bind(this)}
                                                    disabled={!( this.state.name !== '' && this.state.CVCIsValid && this.state.CardNumberIsValid && this.state.ExpirationDateIsValid)}
                                                    className="pull-right btn left10 btn-primary" type="button">
                                                Save
                                            </Button>
                                            <Button onClick={this.props.setupAccountFlow ? this.props.handleSuccessDataSubmit : this.resetFields.bind(this)}
                                                    className="pull-right    btn btn-default right10" type="button">
                                                {this.props.setupAccountFlow ? 'Skip for Now' : 'Cancel'}
                                            </Button>
                                        </FormGroup>
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                    </div>
                }

            </div>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        accountDataReducer: state.accountDataReducer,
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({makeRequest, displayMessage, dispatchAction}, dispatch);
}


export default injectStripe(connect(mapStateToProps, mapDispatchToProps)(AccountBillingSettingsPage))
