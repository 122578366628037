import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
 import Select   from 'react-select'
import {
    makeRequest,
    dispatchAction,
    displayMessage
} from 'actions';
import {
    Panel,
    FormGroup,
    Row,
    Col,
    Checkbox,
    ControlLabel,
    Button,
    Well,
    InputGroup,
    FormControl
} from 'react-bootstrap';
import FaPlusCircle from 'react-icons/lib/fa/plus-circle';
 import {States} from "./../../../shared/constants";
import classNames from 'classnames'
import {UPDATE_USER_DATA} from "actions/actionTypes";
import AddProfessionalModal  from 'shared/AddProfessionalModal';
import config from  '../../../config';
const stateSelectOptions = _.map(States, function (stateName, stateKey) {
    return {'value': stateKey, 'label': stateName};
})
class CreateAgentAccountPage extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {
            agentMLSorLicense: '',
            searchMade: false,
            agentToAdd: {_id: false},
            selectedAgent: 'initial',
            errors: {},
            OfficeState: {},
            showAddProfessionalModal:false,
            AgreeToTerms: false,
            'AgencyName': '',
            'OfficeMLS': '',
            'OfficeName': '',
            'AccountName': '',
            'OfficeStreet': '',
            'OfficePhone': '',
            'OfficeZip': '',
            'OfficeCity': '',
            pending: false,
        }
    };
    componentDidMount() {
        this.fetchTermsOfServiceLink()

    }
    fetchTermsOfServiceLink() {
        this.props.makeRequest({Name:'Documents', type:'TermsOfServiceLink'}, {
            "endpoint": "/get-settings-data"
        }).then(function (res) {

            this.setState({
                TermsOfServiceLink: _.get(res.data, 'data.TermsOfServiceLink.FileURL','')
            });

        }.bind(this));
    }
    searchAgents() {
        var methodData = {
            endpoint: '/agentsReference',
        }
        if (this.state.agentMLSorLicense.length < 5) {
            return this.props.displayMessage({
                'messageType': 'warning',
                'messageCode': 'errorAgentSearchNumberAtLeast',
                'messageData': {'#1#': 5}
            });
        }
        this.props.makeRequest({perPage:25,'MLSorLicense': this.state.agentMLSorLicense}, methodData).then(function (response) {
            this.setState({
                searchMade: true,
                agentToAdd: {_id: false},
                foundAgents: response.data.data,
                //  agentMLSorLicense: ""
            })
        }.bind(this));
    }

    onAgentSearchInputKeyDown(event) {
        switch (event.keyCode) {
            case 9:   // TAB
                // Extend default TAB behavior by doing something here
                break;
            case 13: // ENTER
                // Override default ENTER behavior by doing stuff here and then preventing default
                event.preventDefault();
                this.searchAgents();
                break;
            default:

        }
    }

    validateFormData() {

        var messageData = false, formData = {}, errorFields = {};
        if (!this.state.AgreeToTerms) {
            return {
                messageData: {
                    'messageType': 'warning',
                    'messageCode': 'errorAccountAcceptTermsOfService'
                }, formData
            };
        }
        formData['AgreeToTerms'] = this.state.AgreeToTerms;


        var requiredFields = ['AgencyName', 'OfficeMLS', 'OfficeName', 'AccountName', 'OfficeStreet', 'OfficePhone', 'OfficeZip', 'OfficeCity'];

        _.forEach(requiredFields, function (value) {
            formData[value] = this.state[value];
            if ('' == formData[value]) {
                errorFields[value] = true;
                messageData = {
                    'messageType': 'warning',
                    'messageCode': 'errorFieldsRequired'
                }
            }
        }.bind(this));
        if (!this.state.OfficeState.value) {
            errorFields['OfficeState'] = true;
            messageData = {
                'messageType': 'warning',
                'messageCode': 'errorFieldsRequired'
            }
        }
        formData['TeamName'] = this.state['TeamName'];

        this.setState({errors: errorFields});
        return {messageData, formData};
    }

    createAccount() {

        if (this.state.selectedAgent == 'initial') {
            return this.props.displayMessage({
                'messageType': 'warning',
                'messageCode': 'errorNotSelected',
                'messageData': {'#1#': 'professional'},
            });
        }
        this.setState({pending: true})
        var {messageData, formData} = this.validateFormData();
        if (messageData) {
            this.setState({pending: false})
            return this.props.displayMessage(messageData);
        }
        formData['OfficeAddress'] = {
            Street: formData.OfficeStreet,
            State: this.state.OfficeState.value,
            City: formData.OfficeCity,
            Zip: formData.OfficeZip,
        }
        var agentData = {
            UserID: this.props.User.user._id,
            Roles: ['AccountAdministrator', 'Agent'],
            FirstName: this.state.selectedAgent.FirstName,
            LastName: this.state.selectedAgent.LastName,
            MLS: this.state.selectedAgent.MLS,
            License: this.state.selectedAgent.License,
            Emails: this.state.selectedAgent.Email ? [this.state.selectedAgent.Email ] : []
        };
        formData['accountAgents'] = [agentData];

        var methodData = {
            messageData: {'#1#': 'Account'},
            messageCode: 'recordAdded',
            endpoint: '/accounts',
            type: 'post'
        }
        this.props.makeRequest(formData, methodData).then(function (response) {
            this.props.dispatchAction({
                token: response.data.token,
                user: {
                    ...this.props.User.user,
                    AccountID: response.data.account._id,
                    Roles: _.union(this.props.User.user.Roles, ['Agent', 'AccountAdministrator'])
                }
            }, UPDATE_USER_DATA);

            setTimeout(function () {
                this.props.history.push('/getstarted/billing-details');
            }.bind(this), 3500)

        }.bind(this)).catch(function () {
            this.setState({pending: false})
        }.bind(this))
    }

    addAgent() {

        if (this.state.agentToAdd._id) {
            var updateStateData = {
                selectedAgent: this.state.agentToAdd,
                AgencyName: this.state.agentToAdd.AgencyName,
                OfficeMLS: this.state.agentToAdd.MLS,
                OfficeStreet: this.state.agentToAdd.OfficeAddress,
                OfficeName: this.state.agentToAdd.OfficeName,
                AccountName: (_.get(this.state.agentToAdd, 'FirstName', false) ?  this.state.agentToAdd.FirstName + ' '  : '') +(_.get(this.state.agentToAdd, 'LastName', false) ?  this.state.agentToAdd.LastName + ' ' : '') + this.state.agentToAdd.OfficeName,
                OfficeZip: this.state.agentToAdd.OfficeZip,
                OfficeCity: this.state.agentToAdd.OfficeCity,
                OfficePhone: this.state.agentToAdd.PhoneNumber,
            };
            if (this.state.agentToAdd.OfficeState) {
                updateStateData.OfficeState = {
                    'value': this.state.agentToAdd.OfficeState,
                    'label': States[this.state.agentToAdd.OfficeState]
                }
            }
            if(!this.state.agentToAdd.MLS && !this.state.agentToAdd.License){
                return this.props.displayMessage({
                    'messageType': 'warning',
                    'messageCode': 'errorOneOfTwoFieldsRequired',
                    'messageData': {'#1#': 'MLS', '#2#':'License'}
                });
            }
           this.setState(updateStateData);
         }

    }

    onChangeOfficeState(selectedOption) {
        this.setState({'OfficeState': selectedOption, errors: {...this.state.errors, OfficeState: false}});
    }
    addProfessionalData(professionalData){
        if(!professionalData.MLS && !professionalData.License){
            return this.props.displayMessage({
                'messageType': 'warning',
                'messageCode': 'errorOneOfTwoFieldsRequired',
                'messageData': {'#1#': 'MLS', '#2#':'License'}
            });
        }
         this.setState({selectedAgent:professionalData, showAddProfessionalModal:false})
    }
    renderOptions() {
        return _.map(this.state.foundAgents, function (agent, index) {

            return (
                <div key={agent._id}
                     className={ classNames('agents-options-list__row', {'--selected': agent._id == this.state.agentToAdd._id}) }
                     onClick={() => this.setState({agentToAdd: agent})}
                >
                     <span
                         className="agents-options-list__name"> {agent.FirstName + (agent.LastName ? ' ' + agent.LastName : '') + ' '  } </span>
                    <span
                        className="agents-options-list__mls"> {'MLS ID: ' + (agent.MLS ? agent.MLS : 'n/a')} </span>

                    <span className="agents-options-list__mls"
                    > {'License #: ' + (agent.License ? agent.License : 'n/a')} </span>

                    {agent.RMDLicense &&
                    <span className="agents-options-list__mls"
                    > {'RMD License #: ' + agent.RMDLicense} </span>
                    }

                </div>
            );
        }.bind(this))
    }

    render() {
        console.log('state', this.state);
        return (
            <div className="create-account-page">
                <Panel className="add-agent-box" header="Create account for full access">
                    <Row>
                        <Col   xs={12} className="bot20">
                            <Button onClick={()=>this.setState({showAddProfessionalModal:true})}
                                     className="pull-right btn btn-primary" type="button">
                                Add Professional
                            </Button>
                        </Col>
                    </Row>
                    <FormGroup>
                        <InputGroup>
                            <FormControl type="text"
                                          value={this.state.agentMLSorLicense}
                                         onKeyDown={this.onAgentSearchInputKeyDown.bind(this) }
                                         onChange={(e) => this.setState({agentMLSorLicense: e.target.value}) }
                                         placeholder="Enter Professional MLS # or License #"/>
                            <InputGroup.Addon className="btn btn-primary add-agent-box__search-button btn-primary"
                                              onClick={this.searchAgents.bind(this)}>Search professional</InputGroup.Addon>
                        </InputGroup>
                    </FormGroup>
                    <Well className="add-agent-box__selected-agent-wrapper">
                        <div className="mg-auto font-size20 bot7">Is this the correct professional?
                        </div>
                        <div className="add-agent-box__agent-select-box">
                            <div className="panel-heading">
                                { this.state.searchMade ?
                                    <div className="">
                                        {_.size(this.state.foundAgents) ?
                                            <div className="agents-options-list">
                                                { this.renderOptions()}
                                            </div>
                                            :
                                            <span>No record is found. Don't worry, if you need to add more professionals later you will be able to do so.</span>
                                        }
                                    </div> : ''}
                            </div>
                            <div onClick={  this.addAgent.bind(this)}
                                 className="panel-footer btn green">
                                <div className="text-center top5"><FaPlusCircle className="text-center"></FaPlusCircle>
                                </div>
                                <div className="text-center font-size13 top5 ">Select Professional</div>
                            </div>
                        </div>
                    </Well>
                    <div className="left15 right15  add-agent-box__selected-agent-wrapper">
                        <Row  >
                            <Col sm={6} xs={12} className="top10">
                                <ControlLabel className="ptop5 add-agent-box__selected-agent-data">
                                    { this.state.selectedAgent !== 'initial' ?<span>{ 'Professional: ' + this.state.selectedAgent.FirstName + ' ' + this.state.selectedAgent.LastName}</span> :
                                        <span>&nbsp;</span>}
                                    { this.state.selectedAgent !== 'initial' && (
                                        <span className="left20"> {'MLS ID: ' + this.state.selectedAgent.MLS} </span>) }
                                    { this.state.selectedAgent !== 'initial' && (<span
                                        className="left20"> {'License #: ' + this.state.selectedAgent.License} </span>) }

                                </ControlLabel>
                                <FormControl type="text" className="top12"
                                             style={this.state.errors['AgencyName'] ? {border: '1px solid red'} : {} }
                                             value={this.state.AgencyName}
                                             onChange={(e) => this.setState({AgencyName: e.target.value})}
                                             onFocus={(e) => this.setState({
                                                 errors: {
                                                     ...this.state.errors,
                                                     AgencyName: false
                                                 }
                                             }) }
                                             placeholder="Agency Name"/>
                                <FormControl className="top10" type="text"
                                             value={this.state.TeamName}
                                             onChange={(e) => this.setState({TeamName: e.target.value})}
                                             placeholder="Team Name"/>
                                <FormControl className="top10"
                                             style={this.state.errors['OfficeMLS'] ? {border: '1px solid red'} : {} }
                                             type="text"
                                             value={this.state.OfficeMLS}
                                             onChange={(e) => {
                                                 console.log('e.target.value', e.target.value  );

                                                 this.setState({OfficeMLS: e.target.value})
                                             }}
                                             onFocus={(e) => this.setState({
                                                 errors: {
                                                     ...this.state.errors,
                                                     OfficeMLS: false
                                                 }
                                             }) }
                                             placeholder="Office MLS#"/>
                            </Col>
                            <Col sm={6} xs={12} className="top10">
                                <FormControl style={this.state.errors['OfficeName'] ? {border: '1px solid red'} : {} }
                                             value={this.state.OfficeName}
                                             onChange={(e) => this.setState({OfficeName: e.target.value})} type="text"
                                              onFocus={(e) => this.setState({
                                                 errors: {
                                                     ...this.state.errors,
                                                     OfficeName: false
                                                 }
                                             }) }
                                             placeholder="Office Name"/>

                                    <FormControl className="top10" style={this.state.errors['AccountName'] ? {border: '1px solid red'} : {} }
                                                 value={this.state.AccountName}
                                                 onChange={(e) => this.setState({AccountName: e.target.value})} type="text"
                                                 onFocus={(e) => this.setState({
                                                     errors: {
                                                         ...this.state.errors,
                                                         AccountName: false
                                                     }
                                                 }) }
                                                 placeholder="Account Name"/>
                                <FormControl className="top10"
                                             style={this.state.errors['OfficeStreet'] ? {border: '1px solid red'} : {} }
                                             value={this.state.OfficeStreet}
                                             onChange={(e) => this.setState({OfficeStreet: e.target.value})} type="text"
                                              onFocus={(e) => this.setState({
                                                 errors: {
                                                     ...this.state.errors,
                                                     OfficeStreet: false
                                                 }
                                             }) }
                                             placeholder="Office Address"/>
                                <Row >
                                    <Col sm={4} xs={12} className="top10">
                                        <FormControl
                                            style={this.state.errors['OfficeCity'] ? {border: '1px solid red'} : {} }
                                            value={this.state.OfficeCity}
                                            onChange={(e) => this.setState({OfficeCity: e.target.value})} type="text"
                                             onFocus={(e) => this.setState({
                                                errors: {
                                                    ...this.state.errors,
                                                    OfficeCity: false
                                                }
                                            }) }
                                            placeholder="Office City"/>
                                    </Col>
                                    <Col sm={4} xs={12} className="top10">
                                        <Select
                                            tabIndex="0"
                                            style={this.state.errors['OfficeState'] ? {border: '1px solid red'} : {} }
                                            onFocus={(e) => this.setState({
                                                errors: {
                                                    ...this.state.errors,
                                                    OfficeState: false
                                                }
                                            }) }
                                            searchable={true}
                                            multi={false}
                                            selectedOptions
                                            clearable={false}
                                            placeholder="Office State"
                                            options={stateSelectOptions}
                                            onChange={ this.onChangeOfficeState.bind(this)}
                                            //  filterOptions={filterOptions}
                                            value={this.state.OfficeState}
                                        />
                                    </Col>
                                    <Col sm={4} xs={12}>
                                        <FormControl
                                            style={this.state.errors['OfficeZip'] ? {border: '1px solid red'} : {} }
                                            className="top10" value={this.state.OfficeZip}
                                            onChange={(e) => this.setState({OfficeZip: e.target.value})} type="text"
                                             onFocus={(e) => this.setState({
                                                errors: {
                                                    ...this.state.errors,
                                                    OfficeZip: false
                                                }
                                            }) }
                                            placeholder="Office Zip"/>
                                    </Col>
                                </Row>
                                <FormControl style={this.state.errors['OfficePhone'] ? {border: '1px solid red'} : {} }
                                             className="top10"
                                             value={this.state.OfficePhone}
                                             onChange={(e) => this.setState({OfficePhone: e.target.value})} type="text"
                                             onFocus={(e) => this.setState({
                                                 errors: {
                                                     ...this.state.errors,
                                                     OfficePhone: false
                                                 }
                                             }) }
                                             placeholder="Office Phone"/>
                            </Col>
                        </Row>
                        <Row className="top20">
                            <Col sm={6} xs={12} className="top10">
                                {/*<FormControl type="checkbox"*/}
                                {/*defaultValue={false}*/}
                                {/*/>*/}
                                {/*<ControlLabel>First name</ControlLabel>*/}

                                <div style={{justifyContent:'flex-start'}}  className={'checkbox-wrap top10'}>
                                    <input className="styled-checkbox --inline"
                                            checked={this.state.AgreeToTerms}
                                           onChange={_.noop}

                                           type="checkbox"/>
                                    <label
                                        onClick={e => {
                                             this.setState({AgreeToTerms: !this.state.AgreeToTerms})

                                        } }>
                                         I warrant that I am the professional and agree to the <a target="_blank" href={ _.get(this.state,'TermsOfServiceLink','') }>terms of service</a>
                                    </label>
                                </div>
                            </Col>
                            <Col sm={6} xs={12} className="top10">
                                <FormGroup>
                                    <Button onClick={this.createAccount.bind(this)}
                                            disabled={this.state.selectedAgent === 'initial' || this.state.pending}
                                            className="pull-right btn btn-primary" type="button">
                                        Submit
                                    </Button>
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>


                </Panel>
                <AddProfessionalModal
                    addProfessionalData={this.addProfessionalData.bind(this)}
                    showModal = {this.state.showAddProfessionalModal}
                    displayMessage= {this.props.displayMessage}
                    closeModal={ () => this.setState({
                         showAddProfessionalModal: false
                    })}/>

            </div>
        );


    }
}

function mapStateToProps(state, props) {
    return {
        User: state.userReducer,
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({makeRequest, dispatchAction, displayMessage}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAgentAccountPage);
