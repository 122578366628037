import { USER_LOGOUT, UPDATE_APP_DATA,SET_REDIRECT_URL } from "actions/actionTypes";
var _ = require('lodash');

const initialState = {notification: null   };

export default function (state = initialState, action) {
    switch (action.type) {
        case UPDATE_APP_DATA:
          //  console.log('UPDATE_APP_DATA', state, action );
            var updatedstate = {
                ...state,
                ...action.payload
            };
             return updatedstate;
        // case USER_LOGOUT:
        //     return {
        //         ...state,
         //     };
        default:
             return state;
    }
}
