
import React, { Component  } from 'react';
import GuestLayoutHeader from 'common/GuestLayoutHeader';
import NotFoundPage from 'common/NotFoundPage';




export default class NotFoundPageLayout extends Component {


    render() {
        return (
        <div className="not-found-page">
            <GuestLayoutHeader {...this.props } ></GuestLayoutHeader>
            <div className="container">
                <NotFoundPage/>
            </div>
        </div>
        );
    }
}


