import React, {Component} from 'react';
import {connect} from 'react-redux';
 import {bindActionCreators} from 'redux';
import {FormGroup, Radio, Col,Row, Button} from 'react-bootstrap';
import { UPDATE_ACCOUNT_DATA} from "actions/actionTypes";
import config from  '../../../config';


import {
    makeRequest,
    dispatchAction,

} from 'actions';



class AccountInventorySetup extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {
            inventoryOption: 'inventorySent',

        }
    };
    onSubmit(){
        this.props.makeRequest( {
            InventoryOption: this.state.inventoryOption,
            AccountID: this.props.accountDataReducer.accountData._id,
        },  {
            endpoint: '/account_inventory_setup',
            type: 'post'
        }).then((response) =>{
            this.props.dispatchAction({accountData: response.data}, UPDATE_ACCOUNT_DATA)
            setTimeout(()=>{
                this.props.history.push('/cust/valet');
            },1000)
         })
     }

    render() {
          return (
            <div>
                <Row className="">
                    <Col xs={12} sm={10} md={10} >
                    <h3  className="bot20 top10 pbot20">What is the status of your inventory?</h3>
                    </Col>
                </Row>
                <Row className="">
                    <Col xs={12} sm={10} md={10} >
                    <Radio className="left10" name="radioGroup"
                           checked={(this.state.inventoryOption === 'inventorySent' ) }
                           onChange={() => this.setState({'inventoryOption': 'inventorySent'})}>
                        <div   className="left4 top1 bot10">{config.inventorySetupOptionToTextMap['inventorySent']}</div>
                    </Radio>
                    </Col>
                </Row>
                <Row className="">
                    <Col xs={12} sm={10} md={10} >
                    <Radio className="left10" name="radioGroup"
                           checked={(this.state.inventoryOption === 'inventoryAtCustomerLocation' ) }
                           onChange={() => this.setState({'inventoryOption': 'inventoryAtCustomerLocation'})}>
                        <div  className="left4 top1 bot10">
                            {config.inventorySetupOptionToTextMap['inventoryAtCustomerLocation']} (you can create an errand order to have us pickup on the next screen)
                        </div>
                    </Radio>
                    </Col>
                </Row>
                <Row className="">
                    <Col xs={12} sm={10} md={10} >
                    <Radio className="left10" name="radioGroup"
                           checked={(this.state.inventoryOption === 'inventoryToBeProduced' ) }
                           onChange={() => this.setState({'inventoryOption': 'inventoryToBeProduced'})}>
                        <div   className="left4 top1 bot10">
                            {config.inventorySetupOptionToTextMap['inventoryToBeProduced']}
                        </div>
                    </Radio>
                    </Col>
                </Row>
                <Row className="">
                    <Col xs={12} sm={10} md={10} >
                <FormGroup className="pull-right right20 top20 ptop20">

                    <Button   onClick={this.onSubmit.bind(this)}
                            className="pull-right btn btn-primary" type="button">
                        Submit
                    </Button>
                </FormGroup>
                    </Col>
                </Row>
            </div>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        accountDataReducer: state.accountDataReducer,
        User: state.userReducer,
     };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({makeRequest, dispatchAction }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountInventorySetup);
