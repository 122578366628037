
import React, { Component } from 'react';

export default class Footer extends Component {




    render() {
        return (
            <div className="site-footer">
                {/*<div className="text-center">*/}
                    {/*<ul className="site-footer__linklist">*/}
                        {/*<li className="site-footer__linklist-item"   href="#">About us</li>*/}
                        {/*<li className="site-footer__linklist-item"   href="#">Blog</li>*/}
                        {/*<li className="site-footer__linklist-item"  href="#">Terms of Service</li>*/}
                        {/*<li className="site-footer__linklist-item"  href="#">Privacy policy</li>*/}
                        {/*<li className="site-footer__linklist-item"   href="#">Log in</li>*/}
                    {/*</ul>*/}
                {/*</div>*/}
                {/*<ul className="list--inline site-footer__social-images social-images text-center">*/}
                    {/*<li><a className="social-images__link --facebook-link" href="https://www.facebook.com" title="Facebook">*/}
                         {/*<span className="visually-hidden fallback-text">Facebook</span>*/}

                    {/*</a></li>*/}
                    {/*<li>*/}
                        {/*<a className="social-images__link --twitter-link" href="https://twitter.com" title="Twitter">*/}
                             {/*<span className="visually-hidden fallback-text">Twitter</span>*/}
                        {/*</a>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                        {/*<a className="social-images__link --vimeo-link" href="https://vimeo.com" title="Vimeo">*/}
                             {/*<span className="visually-hidden fallback-text">Vimeo</span>*/}
                        {/*</a>*/}
                    {/*</li>                </ul>*/}
                {/*<div className="site-footer__copyright-content text-center">© 2017</div>*/}
                {/*<div className="site-footer__payment-images">*/}

                    {/*<ul className="payment-images list--inline text-center">*/}
                        {/*<li className="payment-image amazon-payment">*/}
                        {/*</li>*/}
                        {/*<li className="payment-image american-express-payment">*/}
                        {/*</li>*/}
                        {/*<li className="payment-image apple-pay-payment">*/}
                        {/*</li>*/}
                        {/*<li className="payment-image master-payment">*/}
                        {/*</li>*/}
                        {/*<li className="payment-image paypal-payment">*/}
                        {/*</li>*/}
                        {/*<li className="payment-image visa-payment">*/}
                        {/*</li>*/}

                    {/*</ul>*/}

                {/*</div>*/}
            </div>
        );
    }
}

