 import {USER_LOGOUT,
     FETCH_INVENTORIES, ADD_INVENTORY}  from "actions/actionTypes";

var _ = require('lodash');

const initialState = {fetched:false,  data: [], total:0, selectedRecords:[]};
export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_INVENTORIES:
          console.log('FETCH_INVENTORIES', action, action.payload);
            return {
                ...state,
                data: action.payload.data,
                total: action.payload.total,
                fetched:true,
            }
        case ADD_INVENTORY:
            console.log('ADD_INVENTORY', state, action.payload);

            return {
                ...state,
                data: _.concat(state.data, [action.payload]),
                total:state.total+1
             }
        case USER_LOGOUT:
            return initialState;
        default:
            return state;
    }
}
 export const getItem = (state, id) => {
     return state.inventoryReducer.data.filter(record => record._id === id)[0];
 }