 import React, {Component} from 'react';    
import {FormGroup, Radio, ButtonGroup, Button} from 'react-bootstrap';
import FaUser from 'react-icons/lib/fa/user';
import FaUserTimes from 'react-icons/lib/fa/user-times';
export default class ThankYouPage extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {
            selectedType: 'getstarted-professional' //_.isUndefined(this.props.location.query.page) ? 0 : parseInt(this.props.location.query.page) -1,
        };
    };



    render() {
        return (
            <div className="select-account-type-page">
              Thank You for signing up. Please look at your email to validate the email, and continue setting up the account. 
             </div>
      );
}
}

