import React, {Component} from 'react';

import {RouteTransition} from 'react-router-transition';
var NotificationSystem = require('react-notification-system');
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import notificationStyles from 'theme/notificationStyles';
import {dispatchAction} from 'actions';
import Footer from 'common/Footer';
 import {USER_LOGOUT,UPDATE_APP_DATA} from "actions/actionTypes";
 import GuestLayout from 'guests/GuestLayout';
import NotFoundPageLayout from 'common/NotFoundPageLayout';
import Loadable from 'react-loadable';

/* Admin */
  import { Route, Switch } from 'react-router';

const AsyncDashboardLayout = Loadable({
    loader: () => import(/* webpackChunkName: "dashboard" */'dashboard/DashboardLayout'),
    loading: () => null
});
const AsyncAccountLayout = Loadable({
    loader: () => import(/* webpackChunkName: "customer" */'accounts/AccountLayout'),
    loading: () => null
});
/* Client */
 class AppLayout extends Component {
    componentDidMount() {
        // if (!this.props.appDataReducer.notification && this.notificationSystem) {
        //      this.props.dispatchAction({'notification': this.notificationSystem},UPDATE_APP_DATA);
        // }
        var self = this;
         window.addEventListener('storage', function (event) { /* to log out user in all browser tabs and widnows */
             if (event.key == 'reduxPersist:userReducer' ){
                var  userReducerNewData = JSON.parse(event.newValue)
                var  userReducerOldData = JSON.parse(event.oldValue)
                if (userReducerOldData.token && userReducerNewData.token == '') {
                     self.props.dispatchAction({}, USER_LOGOUT);
                }
            }

        });
    }

    render() {
         return (
            <div className="reactPageContainer" >
                <div className="wrapper">
                    <NotificationSystem style={notificationStyles} allowHTML={true}  ref={(el) => {
                         if ( !this.notificationSystem && el) {
                              this.notificationSystem = el;
                             if (!this.props.appDataReducer.notification) {
                                 this.props.dispatchAction({'notification': this.notificationSystem}, UPDATE_APP_DATA);
                             }
                         }
                    }}/>
                    <Switch>
                        <Route path="/rmd"  component={AsyncDashboardLayout} />
                        <Route path="/cust"  component={AsyncAccountLayout} />
                        <Route path=""  component={GuestLayout} />
                        <Route component={NotFoundPageLayout}/>
                    </Switch>

                </div>
                <Footer/>
            </div>
        );
    }
}
function mapStateToProps(state, props) {
    return {
        User: state.userReducer,
        appDataReducer: state.appDataReducer,
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({dispatchAction}, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);