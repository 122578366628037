import React, {Component} from 'react';
import {connect} from 'react-redux';
import ReactDOM from 'react-dom';
import LinkedIn from 'react-linkedin-login'

import {UPDATE_USER_DATA,USER_LOGOUT} from "actions/actionTypes";
import config from  '../../config';
import FaGoogle from 'react-icons/lib/fa/google';
import FaLinkedin from 'react-icons/lib/fa/linkedin';

import {validateEmail}  from 'util/Utils';

import {Link} from 'react-router-dom';

import {Form, FormGroup, Modal, Col, Button, Row, Grid, ControlLabel, FormControl,} from 'react-bootstrap';
import {makeRequest, displayMessage, dispatchAction}  from 'actions';
import GoogleLogin from 'react-google-login';

class AuthenticationPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            IsEmailEmpty: false,
            pending: false,
            signupPage: this.props.location.pathname == '/signup' ? true : false,
            Password: '',
            Email: '',
            errors: {},
            resetPasswordEmail: '',
            newPassword: '',
            showSetPasswordModal: false,
            requestSetPasswordMade: false,
            AgreeToTerms: false,
        }
        this.sendSetPasswordLink = _.throttle(this.sendSetPasswordLink, 2000, {
            'leading': true,
            'trailing': false
        });

        this.setPassword = _.throttle(this.setPassword, 2000, {
            'leading': true,
            'trailing': false
        });
    }

    componentDidMount() {
        this.fetchTermsOfServiceLink()
        if (_.includes(this.props.location.pathname, '/set_password') && this.props.match.params.token) {
            this.checkSetPasswordToken()
        }
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.location.pathname !== this.props.location.pathname) {
            this.setState({Email: '', Password: ''});
        }
        this.setState({signupPage: nextProps.location.pathname == '/signup' ? true : false});
    }
    fetchTermsOfServiceLink() {
        this.props.makeRequest({Name:'Documents', type:'TermsOfServiceLink'}, {
            "endpoint": "/get-settings-data"
        }).then(function (res) {

                this.setState({
                    TermsOfServiceLink: _.get(res.data, 'data.TermsOfServiceLink.FileURL','')
                });

        }.bind(this));
    }
    checkSetPasswordToken() {
        var self = this;
        var methodData = {
            'endpoint': '/check_set_password_token',
            type: 'post'
        };
        self.props.makeRequest({token: self.props.match.params.token}, methodData).then(function (response) {
            self.setState({setPasswordToken:self.props.match.params.token})
            self.props.dispatchAction({}, USER_LOGOUT)
            self.setState({showSetPasswordModal: true});
        })
    }

    onSuccessGoogleAuth(response) {
        this.setState({
            pending: true
        });
        var params = {
            type: 'google',
            accessToken: response.accessToken
        };
        if (this.state.signupPage) {
            this.signup(params)
        } else {
            this.signin(params)
        }
    }

    onFailureGoogleAuth(response) {
        console.log('failure login', response);
    }

    onSuccessLinkedInAuth(response) {
        this.setState({
            pending: true
        });
        var params = {
            type: 'linkedin',
            code: response.code,
            redirect_uri: response.redirectUri
        };
        if (this.state.signupPage) {
            this.signup(params)
        } else {
            this.signin(params)
        }
    }

    signupWithPassword(e) {
        e.preventDefault();
        var FirstName = ReactDOM.findDOMNode(this.refs.formName).value;
        var LastName = ReactDOM.findDOMNode(this.refs.formLastName).value;
        var email = this.state.Email;
        var password = this.state.Password;
        if (FirstName == '') {
            this.setState({
                IsFirstNameEmpty: true
            });
            return this.props.displayMessage({
                'messageType': 'warning',
                'messageCode': 'Please, specify your first name'
            });
        }
        if (LastName == '') {
            this.setState({
                IsLastNameEmpty: true
            });
            return this.props.displayMessage({
                'messageType': 'warning',
                'messageCode': 'Please, specify your last name'
            });
        }
        if (email == '') {
            this.setState({
                IsEmailEmpty: true
            });
            return this.props.displayMessage({
                'messageType': 'warning',
                'messageCode': 'Please, specify your email address'
            });
        }
        if (!validateEmail(email)) {
            this.setState({
                IsEmailEmpty: true
            });
            return this.props.displayMessage({
                'messageType': 'warning',
                'messageCode': 'Email is not valid'
            });
        }
        if (password == '') {
            this.setState({
                IsPasswordEmpty: true
            });
            return this.props.displayMessage({
                'messageType': 'warning',
                'messageCode': 'Please, specify your password'
            });
        }
        if (password.length < 3) {
            this.setState({
                IsPasswordEmpty: true
            });
            return this.props.displayMessage({
                'messageType': 'warning',
                'messageCode': 'Password should include at least 3 symbols'
            });
        }
        if (!this.state.AgreeToTerms) {
            return this.props.displayMessage({
                'messageType': 'warning',
                'messageCode': 'errorAccountAcceptTermsOfService'
            });
        }
        this.setState({
            pending: true
        });
        this.signup({
            type: 'password',
            FirstName: FirstName,
            LastName: LastName,
            email: email,
            password: password
        });
    }

    signinWithPassword(e) {
        e.preventDefault();

        var email = this.state.Email;
        var password = this.state.Password;
        if (email == '') {
            this.setState({
                IsEmailEmpty: true
            });
            return this.props.displayMessage({
                'messageType': 'warning',
                'messageCode': 'Please, specify your email address'
            });
        }
        if (!validateEmail(email)) {
            this.setState({
                IsEmailEmpty: true
            });
            return this.props.displayMessage({
                'messageType': 'warning',
                'messageCode': 'Email is not valid'
            });
        }
        if (password == "") {
            this.setState({
                IsPasswordEmpty: true
            });
            return this.props.displayMessage({
                'messageType': 'warning',
                'messageCode': 'Please, specify your password'
            });
        }
        if (password.length < 3) {
            this.setState({
                IsPasswordEmpty: true
            });
            return this.props.displayMessage({
                'messageType': 'warning',
                'messageCode': 'Password should include at least 3 symbols'
            });
        }
        this.setState({
            pending: true
        });
        this.signin({
            type: 'password',
            email: email,
            password: password
        });
    }

    signin(params) {
        var self = this;
        var methodData = {
            "success_event": UPDATE_USER_DATA,
            'endpoint': '/signin',
            type: 'post'
        };
        self.props.makeRequest(params, methodData).then(function (response) {

                setTimeout(function () { /* to allow update redux state with user data before redirect */
                    self.setState({
                        pending: false
                    }, function () {
                        if (_.get(self.props.history.location, ['state', 'originalPageURL'], false)) {
                            self.props.history.push(_.get(self.props.history.location, ['state', 'originalPageURL'], ''), {});
                        } else if (_.size(_.intersection(response.data.user.Roles, ['CustomerService', 'SuperAdministrator', 'ValetAdministrator', 'PrintAdministrator']))) {
                            self.props.history.push('/rmd/valet');
                        } else if (_.size(_.intersection(response.data.user.Roles, ['Driver']))) {
                            self.props.history.push('/rmd/orders');
                        } else if (!response.data.user.AccountID) {
                            self.props.history.push('/choose-account-type');
                        } else {
                            self.props.history.push('/cust/valet');
                        }
                    })
                    }, 1000)


        }).catch(function (error) {
            self.setState({
                pending: false
            });
        });
    }

    signup(params, methodDataLinkedIn = false) {
        var self = this;
        var methodData = {
            // "success_event": params.type !== 'password' ? UPDATE_USER_DATA : undefined,
            "success_event": UPDATE_USER_DATA,
            'endpoint': '/signup ',
            // messageCode:params.type == 'password' ? 'confirmationEmailSent' : 'userSignedUp',
            // messageCode:  'userSignedUp',
            type: 'post'
        };
        if (methodDataLinkedIn) { /* appDataReducer notification is null for linkedIn in makeRequest*/
            _.extend(methodData, methodDataLinkedIn)
        }
        self.props.makeRequest(params, methodData).then(function (response) {

            self.setState({
                pending: false
            }, function () {
                setTimeout(function () { /* to allow update redux state before redirect */
                    if (params.type == 'password') {
                        self.props.history.push('/thankyou');
                    } else{
                        self.props.history.push('/choose-account-type');
                    }
                }, 1000)
            });
        }).catch(function (error) {
            self.setState({
                pending: false
            });
        });
    }

    sendSetPasswordLink() {
        var self = this;
        if (this.state.resetPasswordEmail == '') {
            this.setState({'errors': {...this.state.errors, resetPasswordEmail: true}});
            return this.props.displayMessage({
                'messageType': 'warning',
                'messageCode': 'errorFieldNotSpecified',
                messageData: {'#1#': 'your email address'}
            });
        }
        var methodData = {
            'endpoint': '/forgot-password',
            'type': 'post',
            'messageCode': 'emailWithResetPasswordLinkSent',
        };
        this.props.makeRequest({'email': this.state.resetPasswordEmail}, methodData).then(function (response) {
            self.setState({
                showModal: false,
            });

        }).catch(function (error) {
        });
    }

    // resetPassword() {
    //     var self = this;
    //     if (this.state.newPassword == '') {
    //         this.setState({'errors': {...this.state.errors, newPassword: true}});
    //         return this.props.displayMessage({
    //             'messageType': 'warning',
    //             'messageCode': 'errorFieldNotSpecified',
    //             messageData: {'#1#': 'your new password'}
    //         });
    //     }
    //     if (this.state.newPassword.length < 3) {
    //         this.setState({'errors': {...this.state.errors, newPassword: true}});
    //         return this.props.displayMessage({
    //             'messageType': 'warning',
    //             'messageCode': 'Password should include at least 3 symbols'
    //         });
    //     }
    //      var methodData = {
    //         'endpoint': '/reset-password',
    //         "success_event": UPDATE_USER_DATA,
    //         'type': 'post'
    //     };
    //     this.props.makeRequest({
    //         'email': this.state.resetPasswordEmail,
    //         'password': this.state.newPassword
    //     }, methodData).then(function (response) {
    //         setTimeout(function () { /* to allow update redux state before redirect */
    //             self.setState({
    //                 showModal: false,
    //                  newPassword: '',
    //              }, function () {
    //
    //
    //                  self.props.displayMessage({
    //                     'messageType': 'success',
    //                     'messageCode': 'passwordHasBeenSet',
    //                      messageData:{'#1#': 'reset'},
    //                 });
    //                 if (_.size(_.intersection(response.data.user.Roles, [ 'CustomerService', 'SuperAdministrator', 'ValetAdministrator', 'PrintAdministrator']))){
    //                     self.props.history.push('/rmd/valet');
    //                 } else  if (_.size(_.intersection(response.data.user.Roles, [ 'Driver' ]))){
    //                     self.props.history.push('/rmd/orders');
    //                 }  else if (!response.data.user.AccountID) {
    //                     self.props.history.push('/choose-account-type');
    //                 } else {
    //                     self.props.history.push('/cust/valet');
    //                 }
    //
    //
    //             })
    //         }, 1000)
    //     }).catch(function (error) {
    //      });
    //
    // }
    setPassword() {
        var self = this;

        if (this.state.newPassword == '') {
            this.setState({'errors': {...this.state.errors, newPassword: true}});
            return this.props.displayMessage({
                'messageType': 'warning',
                'messageCode': 'errorFieldNotSpecified',
                messageData: {'#1#': 'your new password'}
            });
        }

        if (this.state.newPassword.length < 3) {
            this.setState({'errors': {...this.state.errors, newPassword: true}});
            return this.props.displayMessage({
                'messageType': 'warning',
                'messageCode': 'Password should include at least 3 symbols'
            });
        }
        this.setState({'requestSetPasswordMade': true});
        var methodData = {
            'endpoint': '/set-password',
            "success_event": UPDATE_USER_DATA,
            'type': 'post'
        };
        this.props.makeRequest({
            'token':self.state.setPasswordToken,
            'password': this.state.newPassword,
        }, methodData).then(function (response) {
            setTimeout(function () { /* to allow update redux state before redirect */
                self.setState({
                    showSetPasswordModal: false,
                    requestSetPasswordMade: false,
                    newPassword: '',
                }, function () {


                    self.props.displayMessage({
                        'messageType': 'success',
                        'messageCode': 'passwordHasBeenSet',
                        messageData: {'#1#': 'set'},
                    });
                    if (_.size(_.intersection(response.data.user.Roles, ['CustomerService', 'SuperAdministrator', 'ValetAdministrator', 'PrintAdministrator']))) {
                        self.props.history.push('/rmd/valet');
                    } else if (_.size(_.intersection(response.data.user.Roles, ['Driver']))) {
                        self.props.history.push('/rmd/orders');
                    } else if (!response.data.user.AccountID) {
                        self.props.history.push('/choose-account-type');
                    } else {
                        self.props.history.push('/cust/valet');
                    }

                })
            }, 1000)
        }).catch(function (error) {
            self.setState({'requestSetPasswordMade': false});
        });

    }

    render() {
        console.log('app version number', config.appVersion);
        var linkedInButtonContent = (
            <div  >
                { this.state.pending || (this.state.signupPage && !this.state.AgreeToTerms) &&
                <div className="linkdedInButtonOverflowBox" onClick={(e) => {
                    if (this.state.pending || (this.state.signupPage && !this.state.AgreeToTerms)) { /* to not open LinkedIn modal window */
                        e.stopPropagation()
                    }
                }}></div>
                }
                <div className={'loginButtonPictBox'}
                     style={this.state.pending || (this.state.signupPage && !this.state.AgreeToTerms) ? {} : {}  }>
                    <FaLinkedin
                        className={'loginButtonPictText' + (  this.state.pending || (this.state.signupPage && !this.state.AgreeToTerms) ? ' grayedout' : '')}></FaLinkedin>
                </div>
                <span style={{textAlign: 'left'}}>Continue with LinkedIn</span>

            </div>);

        return (
            <div className="authentication-page">
                <div className="login-header">
                    <div className="login-header__image-wrapper">
                        {/*<img src="/theme/assets/nav_logo.png"/>*/}
                        {/*<img src="http://rmdsigns.com/Images/rmd%20medium.jpg"/>*/}
                        <img src={`${config.getEnvironment().serverBaseURL}/assets/Logo-Color-200px-wide.png`}/>
                    </div>
                </div>
                <Grid className="login-content">
                    <Row  >

                        <Col className="login-content__text-box col-sm-push-6" sm={6} xs={12}>

                            <div
                                className="login-content__header bot7">  {this.state.signupPage ? 'Get Started' : "Don't have an account?"} </div>
                            <div style={{minHeight: '51px'}} className="bot12 pright9">Start boosting your efficiency
                                with our Valet Service.
                                Through our Valet Portal, you can install and remove signs,
                                order new listing panels, and more.
                            </div>

                            {this.state.signupPage &&
                            <div className="login-content__header top15 bot3">Already have an account?</div>
                            }
                            <div>
                                {this.state.signupPage ? (
                                    <div>
                                        If you already have an RMD Signs Valet account, then login&nbsp;
                                        <Link
                                            onClick={(e) => this.setState({
                                                IsPasswordEmpty: false,
                                                IsEmailEmpty: false
                                            })}
                                            to={'/signin'}>right here</Link>
                                    </div>) : (
                                    <div>
                                        <Link className={'btn btn-primary ' + ( this.state.pending ? 'grayedout' : '')}
                                              onClick={(e) => {
                                                  // e.preventDefault()
                                                  // return this.props.displayMessage({
                                                  //      'messageCode': 'Sorry, creating new accounts is temporary disabled',
                                                  //  });

                                                  this.setState({
                                                      IsPasswordEmpty: false,
                                                      IsEmailEmpty: false
                                                  })
                                              }}
                                              to={'/signup'}
                                              style={{fontFamily: 'Work Sans, HelveticaNeue, Helvetica Neue, sans-serif'}}>
                                            Create Account
                                        </Link>
                                    </div>)
                                }
                            </div>
                        </Col>


                        <Col className="login-content__form-box col-sm-pull-6 bot20" sm={6} xs={12}>
                            <Form horizontal
                                  onSubmit={ this.state.signupPage ? this.signupWithPassword.bind(this) : this.signinWithPassword.bind(this)}>
                                {this.state.signupPage &&
                                <FormGroup >
                                    <Col className="ptop5 align-text-right" componentClass={ControlLabel} xs={2}>
                                    </Col>
                                    <Col className="pleft0" xs={10}>
                                        <FormControl type="name" ref="formName"
                                                     style={this.state.IsFirstNameEmpty ? {border: '1px solid red'} : {} }
                                                     onChange={(e) => this.setState({IsFirstNameEmpty: false})}
                                                     placeholder="First name"/>
                                    </Col>
                                </FormGroup>
                                }
                                {this.state.signupPage &&
                                <FormGroup >
                                    <Col className="ptop5 align-text-right" componentClass={ControlLabel} xs={2}>
                                    </Col>
                                    <Col className="pleft0" xs={10}>
                                        <FormControl type="name"
                                                     style={this.state.IsLastNameEmpty ? {border: '1px solid red'} : {} }
                                                     onChange={(e) => this.setState({IsLastNameEmpty: false})}
                                                     ref="formLastName" placeholder="Last name"/>
                                    </Col>
                                </FormGroup>
                                }
                                <FormGroup >
                                    <Col className="ptop5 align-text-right" componentClass={ControlLabel} xs={2}>
                                    </Col>
                                    <Col className="pleft0 " xs={10}>
                                        <FormControl type="email"
                                                     style={this.state.IsEmailEmpty ? {border: '1px solid red'} : {} }
                                                     onChange={(e) => this.setState({
                                                         Email: e.target.value,
                                                         IsEmailEmpty: false
                                                     })}
                                                     value={this.state.Email} placeholder="Email"/>
                                    </Col>
                                </FormGroup>

                                <FormGroup >
                                    <Col className="ptop5 align-text-right" componentClass={ControlLabel} xs={2}>
                                    </Col>
                                    <Col className="pleft0" xs={10}>
                                        <FormControl
                                            style={this.state.IsPasswordEmpty ? {border: '1px solid red'} : {} }
                                            onChange={(e) => this.setState({
                                                Password: e.target.value,
                                                IsPasswordEmpty: false
                                            })} type="password"
                                            value={this.state.Password} placeholder="Password"/>
                                    </Col>
                                </FormGroup>
                                {this.state.signupPage &&
                                <FormGroup >
                                    <Col className="pleft0 pull-right" xs={10}>
                                        <input className="styled-checkbox --inline"
                                               checked={this.state.AgreeToTerms}
                                               onChange={_.noop}

                                               type="checkbox"/>
                                        <label
                                            onClick={e => {
                                                this.setState({AgreeToTerms: !this.state.AgreeToTerms})

                                            } }>
                                            I agree to the <a target="_blank" href={ _.get(this.state,'TermsOfServiceLink','') }>terms of service</a>
                                        </label>
                                    </Col>
                                </FormGroup>
                                }

                                <FormGroup>

                                    <Col className="pull-right  right15">
                                        <Button
                                            style={{justifyContent: 'flex-end', display: 'flex', textAlign: 'right'}}
                                            className={'btn btn-primary pull-right ' + ( this.state.pending ? 'grayedout' : '')}
                                            type="submit" disabled={this.state.pending}>
                                            {this.state.signupPage ? 'Get Started' : 'Sign in'}
                                        </Button>
                                        {!this.state.signupPage &&
                                        <div className="clear" >
                                            <a style={{fontSize: 9}} className="green text-bold displayAsLink"
                                               onClick={(e) => {
                                                 //  e.preventDefault(); /* maybe it is not supported on some system and nothing happens when user clicks on it*/
                                                   this.setState({'showModal': true})
                                               }
                                               }>Forgot Password?</a>
                                        </div>
                                        }
                                    </Col>
                                </FormGroup>
                            </Form>
                            <Col xs={12} className='pright0 top15 bot20'>
                                <GoogleLogin
                                    clientId={config.Google.GooglePlusClientID}
                                    disabled={this.state.pending || (this.state.signupPage && !this.state.AgreeToTerms)}
                                    className="googleButton"
                                    disabledStyle={{backgroundColor: '#ABD0CA'}}
                                    onSuccess={this.onSuccessGoogleAuth.bind(this)}
                                    onFailure={this.onFailureGoogleAuth.bind(this)}
                                >
                                    <div className="loginButtonPictBox" style={{
                                        // float: 'left',
                                        // marginLeft: 5,
                                        // borderRadius: 3,
                                        // backgroundColor: '#fff'
                                    }}>
                                        <FaGoogle
                                            className={'loginButtonPictText' + (  this.state.pending || (this.state.signupPage && !this.state.AgreeToTerms) ? ' grayedout' : '')}>

                                        </FaGoogle>
                                    </div>
                                    <span style={{textAlign: 'left'}}>Continue with Google</span>
                                </GoogleLogin>
                            </Col>

                            <Col xs={12} className='pbot1 pright0'>

                                <LinkedIn
                                    clientId={config.LinkedIn.clientID}
                                    callback={this.onSuccessLinkedInAuth.bind(this)}
                                    className={'linkedInButton ' + ( this.state.pending || (this.state.signupPage && !this.state.AgreeToTerms) ? 'grayedout' : '')}
                                    text={linkedInButtonContent}/>
                            </Col>
                        </Col>


                    </Row>
                </Grid>
                <Modal show={this.state.showModal} onHide={() => {
                    this.setState({
                        showModal: false,
                        newPassword: '',
                    });
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Reset password</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form className="clearfix " horizontal>
                            <FormGroup className="left0 bot0">

                                <Row className="bot20">
                                    <Col md={8} sm={9} xs={12}>
                                        <ControlLabel className="ptop0 bot10">Email</ControlLabel>
                                        <FormControl type="email" placeholder=""
                                                     onFocus={(e) => this.setState({
                                                         errors: {
                                                             ...this.state.errors,
                                                             resetPasswordEmail: false
                                                         }
                                                     }) }
                                                     style={this.state.errors['resetPasswordEmail'] ? {border: '1px solid red'} : {} }
                                                     onChange={(e) => this.setState({
                                                         resetPasswordEmail: e.target.value
                                                     })}
                                                     value={this.state.resetPasswordEmail}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Form>

                    </Modal.Body>
                    <Modal.Footer>
                        <FormGroup>
                            <Row className="bot20 left0">
                                <Col md={12} sm={12} xs={12} className="pright0">
                                    <Button
                                        className=" pull-right bot10 btn btn-primary right20"
                                        onClick={(e) => this.sendSetPasswordLink()} type="button">
                                        Send reset password link
                                    </Button>
                                    <Button className="pull-right btn  btn-default right15" type="button"
                                            onClick={() => {
                                                this.setState({
                                                    showModal: false,
                                                    newPassword: '',
                                                });
                                            }}>
                                        Cancel
                                    </Button>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showSetPasswordModal} onHide={() => {
                    this.setState({
                        showSetPasswordModal: false,
                        requestSetPasswordMade: false,
                        newPassword: '',
                    });
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Set password</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Form className="clearfix " horizontal>
                            <FormGroup className="left0 bot0">

                                <Row className="bot20">
                                    <Col md={8} sm={9} xs={12}>
                                        <ControlLabel className="ptop0 bot10">New Password</ControlLabel>
                                        <FormControl autoComplete="new-password" type="password" placeholder=""
                                                     onFocus={(e) => this.setState({
                                                         errors: {
                                                             ...this.state.errors,
                                                             newPassword: false
                                                         }
                                                     }) }
                                                     style={this.state.errors['newPassword'] ? {border: '1px solid red'} : {} }
                                                     onChange={(e) => this.setState({
                                                         newPassword: e.target.value
                                                     })}
                                                     value={this.state.newPassword}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Form>

                    </Modal.Body>
                    <Modal.Footer>
                        <FormGroup>
                            <Row className="bot20 left0">
                                <Col md={12} sm={12} xs={12} className="pright0">


                                    <Button disabled={this.state.requestSetPasswordMade}
                                            className=" pull-right btn btn-primary right20"
                                            onClick={(e) => this.setPassword()} type="button">
                                        Set password
                                    </Button>

                                    <Button className="pull-right btn  btn-default right15" type="button"
                                            onClick={() => {
                                                this.setState({
                                                    showSetPasswordModal: false,
                                                    requestSetPasswordMade: false,
                                                    newPassword: '',
                                                });
                                            }}>
                                        Cancel
                                    </Button>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Modal.Footer>
                </Modal>
            </div >
        )
            ;

    }
}

function mapStateToProps(state) {
    return {User: state.userReducer,}
}

export default connect(mapStateToProps, {makeRequest, displayMessage,dispatchAction})(AuthenticationPage);
