import {  UPDATE_DEFAULT_PRICING, FETCH_DEFAULT_PRICING,USER_LOGOUT }  from "actions/actionTypes";

var _ = require('lodash');

const initialState = {data: [], total:0, fetched:false };
export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_DEFAULT_PRICING:
            console.log(FETCH_DEFAULT_PRICING, action.payload);
            return {
                data: action.payload.data,
                total: action.payload.total,
                fetched:true,
            }
        case UPDATE_DEFAULT_PRICING:
            console.log(UPDATE_DEFAULT_PRICING, action.payload);
            var itemIndex = _.findIndex(state.data, {'_id': action.payload._id});
            var updatedRecords = [...state.data.slice(0, itemIndex),
                action.payload,
                ...state.data.slice(itemIndex + 1)];
            return {
                ...state,
                data: updatedRecords
            }
        case USER_LOGOUT:
            return initialState;
        default:
            return state;
    }
}
