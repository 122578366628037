import {USER_LOGOUT, UPDATE_ACCOUNT_TO_EDIT}  from "actions/actionTypes";

var _ = require('lodash');

const initialState = {data: {}, fetched:false };
export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_ACCOUNT_TO_EDIT:
      console.log(UPDATE_ACCOUNT_TO_EDIT, action.payload);
       return {
           fetched:true,
            data:  action.payload.data && action.payload.data[0] ?  action.payload.data[0] : {},
      }

      case USER_LOGOUT:
          return initialState;
    default:
      return state;
  }
}
