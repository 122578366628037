import React, {Component} from 'react';

import {FormGroup, Radio, ButtonGroup, Button} from 'react-bootstrap';
import FaUser from 'react-icons/lib/fa/user';
import FaUserTimes from 'react-icons/lib/fa/user-times';
export default class AccountSelectTypePage extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {
            selectedType: 'getstarted-professional' //_.isUndefined(this.props.location.query.page) ? 0 : parseInt(this.props.location.query.page) -1,
        };


    };

    onAccountSelect(value) {
        this.setState({
            selectedType: value
        })
    }
    goToNextScreen(){
        this.props.history.push('/'+this.state.selectedType);
    }

    render() {
        return (
            <div className="select-account-type-page">
                <div className="button-box">
                    <Button onClick={this.onAccountSelect.bind(this, "getstarted-professional")}>

                        <Radio onChange={function(){}} checked={ this.state.selectedType == 'getstarted-professional' ? true : false}
                               className="pull-left">

                        </Radio>
                        <div className="button-box__pict-box">
                            <FaUser className="button-box__pict"></FaUser>
                        </div>
                        <div className="button-box__text-box">
                            <div className="button-box__large-text">Professional Account</div>
                            <div className="button-box__small-text">Select this if you are the professional</div>
                        </div>
                    </Button >
                    <Button onClick={this.onAccountSelect.bind(this, 'getstarted-office')}>

                        <Radio onChange={function(){}}  checked={ this.state.selectedType == 'getstarted-office' ? true : false}
                               className="pull-left">
                        </Radio>
                        <div className="button-box__pict-box">
                            <FaUserTimes className="button-box__pict"></FaUserTimes>
                        </div>
                        <div className="button-box__text-box">
                            <div className="button-box__large-text">Office Account</div>
                            <div className="button-box__small-text">Select this if you are setting account for one or more professionals</div>
                        </div>
                    </Button >
                    <div className="clearfix"></div>
                </div>
                <div className="clearfix"></div>
                 <button onClick={this.goToNextScreen.bind(this)} className="btn btn-primary  continue-button" >Continue</button>
            </div>
        );
    }
}

