import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Navbar, NavItem, Button, Nav, NavbarItems, MenuItem, NavDropdown} from 'react-bootstrap';
import {USER_LOGOUT} from "actions/actionTypes";

import {LinkContainer} from 'react-router-bootstrap';
import config from  '../../config';


class HeaderLinksBox extends NavItem { /* to prevent warnings about undefined props in div from React*/
    render() {
        return (<div className="header-right-links-box">
            <li role="presentation" className="pright8"><a target="_blank" href={config.pageLink1}>Help</a></li>
            { this.props.User.token ?
                     <li className="link-box">
                        <div className="link-item-top-text">{_.isUndefined(_.get(this.props, 'User.user.FirstName')) ? '' : 'Hi, '+_.get(this.props, 'User.user.FirstName')}</div>
                        <NavItem eventKey={4}   onSelect={this.props.logoutUser }>Log out</NavItem>
                    </li> :
                    <LinkContainer className="authLink" to="/signin">
                        <NavItem eventKey={6}>Sign in</NavItem>
                    </LinkContainer>
            }

        </div>)
    }
}

 class GuestLayoutHeader extends Component {


    logoutUser() {
        this.props.dispatchAction({}, USER_LOGOUT)
    }

    render() {
        return (
            <Navbar className="header-container" collapseOnSelect>
                <Navbar.Header>
                    <Navbar.Brand>
                        <Link to="/" className="">
                            <img src={`${config.getEnvironment().serverBaseURL}/assets/Logo-White-200px-wide.png`}/>
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle />
                </Navbar.Header>
                { _.isUndefined( _.find(['/signin', '/signup', '/thankyou', '/set_password'],(url)=>{
                    return _.includes(this.props.location.pathname, url)
                })) &&
                <Navbar.Collapse>

                    <Nav>
                        <HeaderLinksBox logoutUser={this.logoutUser.bind(this)}  {...this.props}/>
                    </Nav>
                </Navbar.Collapse>
                }
            </Navbar>
        );
    }
}

function mapStateToProps(state) {
    return {User: state.userReducer}
}

export default connect(mapStateToProps, {})(GuestLayoutHeader);
