
import { UPDATE_USER_DATA, USER_LOGOUT} from "actions/actionTypes";
 import _ from "lodash";

import {REHYDRATE} from 'redux-persist/constants'
const initialState = {
    token:'',
  //  agentData:{},
    user:  {},
    // accountData:[],
   // accountAgents:[]
}
export default function userReducer(state = initialState, action) {
    switch (action.type) {

        case REHYDRATE: /* this is required to populate reducer state from data saved in persisted store */
             if (_.isUndefined(action.payload.userReducer)){
                return   state;
            } else {
                return action.payload.userReducer
            }
        case UPDATE_USER_DATA:
 console.log('UPDATE_USER_DATA', action.payload, state  );
            return {
                ...state,
                 user: action.payload.user ? action.payload.user : {...state.user},
               // agentData: action.payload.agentData ? action.payload.agentData : {...state.agentData},
              //  accountData : action.payload.accountData ? action.payload.accountData : {...state.accountData},
              //  AccountID : action.payload.AccountID ? action.payload.AccountID : state.AccountID,
             //   accountAgents : action.payload.accountAgents ? action.payload.accountAgents : [...state.accountAgents],
                token:action.payload.token ? action.payload.token : state.token
            };

        case USER_LOGOUT:
            console.log('in USER_LOGOUT ' );
            return initialState;

        default:
            return state;
    }
}