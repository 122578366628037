
import React, { Component  } from 'react';




export default class NotFoundPage extends Component {


    render() {
        return (
            <div>
                <h3 className="text-green bold">Oops!</h3>

                <p>This Page Was Not Found.</p>

                <p>Please go back to the <a href="/">Main Page.</a></p>
            </div>
        );
    }
}


