import {  FETCH_ALL_EMAIL_TYPES,UPDATE_EMAIL_TYPE }  from "actions/actionTypes";

var _ = require('lodash');

const initialState = {data: [] };
export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_ALL_EMAIL_TYPES:
            console.log(FETCH_ALL_EMAIL_TYPES, action.payload);
            return {
                data: action.payload.data,
                types: action.payload.types
            }
        case UPDATE_EMAIL_TYPE:
            console.log('UPDATE_EMAIL_TYPE', state, action.payload);
            var itemIndex = _.findIndex(state.data, {'_id': action.payload._id});
            var updatedRecords = [...state.data.slice(0, itemIndex),
                action.payload,
                ...state.data.slice(itemIndex + 1)];
            return {
                ...state,
                data: updatedRecords
            }
        default:
            return state;
    }
}
