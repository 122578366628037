import React, {Component} from 'react';

import {Button, Modal, Form, FormControl, FormGroup, Row, ControlLabel, Col,} from 'react-bootstrap';
import {validateEmail}  from 'util/Utils';
import uuid  from 'uuid';
export default class AddProfessionalModal extends Component {

    constructor(props, context) {
        super(props, context);
         this.state = {
             FirstName:"",
            LastName:"",
             Email:"",
             MLS:'',
             License:'',
             errors:{},
        };
     }
     closeModal(){
       this.props.closeModal()
    }

    addProfessional(){
         var dataToSave = _.pick(this.state, ['MLS', 'License', 'FirstName', 'LastName', 'Email'])
        if (dataToSave.FirstName == '') {
            this.setState({errors: {...this.state.errors, FirstName: true}});
            return this.props.displayMessage({
                'messageType': 'warning',
                'messageData': {'#1#': 'First Name'},
                'messageCode': 'errorFieldRequired'
            });
        }
        if (dataToSave.Email == '') {
            this.setState({errors: {...this.state.errors, Email: true}});
            return this.props.displayMessage({
                'messageType': 'warning',
                'messageData': {'#1#': 'Email'},
                'messageCode': 'errorFieldRequired'
            });
        }
        if (!validateEmail(dataToSave.Email)) {
            return this.props.displayMessage({
                'messageType': 'warning',
                'messageData': {'#1#': 'Email'},
                'messageCode': 'errorValueIsNotValid'
            });
        }
        dataToSave._id =  uuid.v4()
         this.props.addProfessionalData(dataToSave)
    }

    render() {
        if (!this.props.showModal){
            return null;
        }
         return (
             <Modal   dialogClassName=""
                                    show={this.props.showModal} onHide={() => {
                 this.closeModal()
             }}>
                 <Modal.Header closeButton>
                     <Modal.Title>Add Professional</Modal.Title>
                 </Modal.Header>
                 <Modal.Body  >
                     <Form className="clearfix " horizontal>
                         <FormGroup className="left0 right0 bot0">
                             <Row className="bot20">
                                 <Col md={8} sm={9} xs={12}>
                                     <ControlLabel className="ptop5 bot5">First Name</ControlLabel>
                                     <FormControl type="text" placeholder="First Name"
                                                  onFocus={(e) => this.setState({
                                                      errors: {
                                                          ...this.state.errors,
                                                          FirstName: false
                                                      }
                                                  })}
                                                  style={this.state.errors['FirstName'] ? {border: '1px solid red'} : {}}
                                                  onChange={(e) =>   this.setState({
                                                      FirstName: e.target.value
                                                  })}
                                                  value={this.state.FirstName}
                                                  ref="FirstName"/>
                                 </Col>
                             </Row>
                             <Row className="bot20">
                                 <Col md={8} sm={9} xs={12}>
                                     <ControlLabel className="ptop5 bot5">Last Name</ControlLabel>
                                     <FormControl type="text" placeholder="Last Name"
                                                  onFocus={(e) => this.setState({
                                                      errors: {
                                                          ...this.state.errors,
                                                          LastName: false
                                                      }
                                                  })}
                                                  style={this.state.errors['LastName'] ? {border: '1px solid red'} : {}}
                                                  onChange={(e) =>   this.setState({
                                                      LastName: e.target.value
                                                  })}
                                                  value={this.state.LastName}
                                                  ref="LastName"/>
                                 </Col>
                             </Row>
                             <Row className="bot20">
                                 <Col md={8} sm={9} xs={12}>
                                     <ControlLabel className="ptop5 bot5">Email</ControlLabel>
                                     <FormControl type="text" placeholder="Email"
                                                  onFocus={(e) => this.setState({
                                                      errors: {
                                                          ...this.state.errors,
                                                          Email: false
                                                      }
                                                  })}
                                                  style={this.state.errors['Email'] ? {border: '1px solid red'} : {}}
                                                  onChange={(e) =>   this.setState({
                                                      Email: e.target.value
                                                  })}
                                                  value={this.state.Email}
                                     />
                                 </Col>
                             </Row>
                             <Row className="bot20">
                                 <Col md={8} sm={9} xs={12}>
                                     <ControlLabel className="ptop5 bot5">MLS</ControlLabel>
                                     <FormControl type="text" placeholder="MLS"
                                                  
                                                  style={this.state.errors['LastName'] ? {border: '1px solid red'} : {}}
                                                  onChange={(e) =>   this.setState({
                                                      MLS: e.target.value
                                                  })}
                                                  value={this.state.MLS}
                                                  ref="MLS"/>
                                 </Col>
                             </Row>
                             <Row className="bot20">
                                 <Col md={8} sm={9} xs={12}>
                                     <ControlLabel className="ptop5 bot5">License</ControlLabel>
                                     <FormControl type="text" placeholder="License"

                                                  style={this.state.errors['LastName'] ? {border: '1px solid red'} : {}}
                                                  onChange={(e) =>   this.setState({
                                                      License: e.target.value
                                                  })}
                                                  value={this.state.License}
                                                  ref="License"/>
                                 </Col>
                             </Row>
                         </FormGroup>
                     </Form>

                   </Modal.Body>
                 <Modal.Footer>
                          <Row className="bot20 left0">
                             <Col md={12} sm={12} xs={12} className="pleft0">
                                 <Button className="pull-right btn btn-primary left20" type="button"
                                         onClick={() => {
                                             this.addProfessional()
                                         }}>
                                     Add Professional
                                 </Button>
                                 <Button className="pull-right btn  btn-default" type="button"
                                         onClick={() => {
                                             this.closeModal()
                                         }}>
                                     Cancel
                                 </Button>
                             </Col>
                         </Row>
                  </Modal.Footer>
             </Modal>
        );
    }
}

