import React, {Component} from 'react';

import {Button, Modal,Row, Col, FormControl } from 'react-bootstrap';

export default class ConfirmModalDialog extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            inputText: '',
            confirmationDone: false,
        };
        this.confirmAction = _.throttle(this.confirmAction, 2000, {
            'leading': true,
            'trailing': false
        });
    }
    confirmAction(data){
        this.setState({inputText: false, confirmationDone: false})
         this.props.confirmActionHandler(data)
    }
    cancel(){
        this.setState({inputText: false, confirmationDone: false})
        this.props.closeModal()
        if (this.props.cancelActionHandler) {
            this.props.cancelActionHandler()
        }
    }
    closeModal() {
        this.setState({inputText: false, confirmationDone: false})
        this.props.closeModal()
    }
    onInputTextChange(e) {
        var updatedState = {inputText: e.target.value}
        console.log('on input change'   );
        if (e.target.value.toLowerCase() == 'yes') {
            updatedState.confirmationDone = true;
        } else {
            updatedState.confirmationDone = false;
        }
        this.setState(updatedState)
    }

    render() {
          if (!this.props.show) {
            return null;
        }
         return (
        <Modal  show={this.props.show}
                  onHide={() => {
                      this.closeModal()
                  }}
                 >
            <Modal.Header>

                    <Modal.Title>{ this.props.title}</Modal.Title>

            </Modal.Header>
            {/*{this.props.type == 'confirmationWithText' &&*/}
            {/*<Modal.Body >*/}
                {/*<div className="font-size18 bot20"> {this.props.title}</div>*/}
                 {/*<Row>*/}
                    {/*<Col lg={54} md={5} sm={8} xs={12}>*/}
                        {/*<FormControl type="text"*/}
                                     {/*onChange={this.onInputTextChange.bind(this)}*/}
                        {/*/>*/}
                    {/*</Col>*/}
                 {/*</Row>*/}
            {/*</Modal.Body>*/}
            {/*}*/}
            {!_.isUndefined(this.props.dialogContent)  &&
            <Modal.Body >
                {this.props.dialogContent()}
            </Modal.Body>
            }
            <Modal.Footer>
                 <Button
                    onClick={(e) => this.cancel()}>{this.props.cancelButtonTitle ? this.props.cancelButtonTitle : 'Cancel'}</Button>
                { _.get(this.props, 'buttonTitle') !== 'doNotDisplayConfirmButton' &&
                <Button
                    disabled={this.props.type == 'confirmationWithText' ? !this.state.confirmationDone : ( this.props.confirmButtonDisabled ? this.props.confirmButtonDisabled() : false)}
                    onClick={(e) => this.confirmAction({buttonNumber: 1}) } className="left20"
                    bsStyle="primary">{this.props.buttonTitle ? this.props.buttonTitle : 'Confirm'}</Button>
                }
                {!_.isUndefined(this.props.secondButtonTitle)  && _.get(this.props, 'secondButtonTitle') !== 'doNotDisplayConfirmButton' &&
                <Button
                    onClick={(e)=>  this.confirmAction({buttonNumber:2}) } className="left20" bsStyle="primary">{ this.props.secondButtonTitle  }</Button>
                }

            </Modal.Footer>
        </Modal>


        );
    }
}

