 import {  USER_LOGOUT, FETCH_ALL_ACCOUNTS }  from "actions/actionTypes";

var _ = require('lodash');

const initialState = {data: [] };
export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_ALL_ACCOUNTS:
            console.log(FETCH_ALL_ACCOUNTS, action.payload);
            return {
                data: action.payload.data,
            }
        case USER_LOGOUT:
            return initialState;
        default:
            return state;
    }
}
