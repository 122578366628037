import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Link} from 'react-router-dom';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import Select   from 'react-select'
import {validateEmail, getFieldByPathFormatter}  from 'util/Utils';
import {States} from "./../../../shared/constants";
import AddProfessionalModal  from 'shared/AddProfessionalModal';
import config from  '../../../config';

import {
    makeRequest,
    dispatchAction,
    displayMessage
} from 'actions';
import {
    Panel,
    FormGroup,
    Tabs,
    Tab,
    Row,
    Col,
    Checkbox, Radio,
    ControlLabel,
    Button,
    Well,
    InputGroup,
    FormControl
} from 'react-bootstrap';
import FaPlusCircle from 'react-icons/lib/fa/plus-circle';
import FaTimesCircle from 'react-icons/lib/fa/times-circle';
import {UPDATE_USER_DATA} from "actions/actionTypes";
const stateSelectOptions = _.map(States, function (stateName, stateKey) {
    return {'value': stateKey, 'label': stateName};
})
import classNames from 'classnames'
class CreateOfficeAccountPage extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {
            agentMLSorLicense: '',
            searchMade: false,
            agentToAdd: {_id: false},
            selectedAgents: [],
            errors: {},
            activeTab: '',
            RolesTabEnabled: false,
            displayErrorsRolesTab: false,
            ContactsTabEnabled: false,
            showAddProfessionalModal:false,
            OfficeState: {},
            AgreeToTerms: false,
            rolesTabActiveView: 'SelectCurrentUserAgent',
            currentUserAgent: 'notAnAgent',
            'AgencyName': '',
            'OfficeMLS': '',
            'OfficeName': '',
            'AccountName':'',
            'OfficeStreet': '',
            'OfficePhone': '',
            'OfficeZip': '',
            'OfficeCity': ''
        }
console.log('in create office account page' );
    };

    searchAgents() {

        var methodData = {
            endpoint: '/agentsReference',
        }
        if (this.state.agentMLSorLicense.length < 5) {
            return this.props.displayMessage({
                'messageType': 'warning',
                'messageCode': 'errorAgentSearchNumberAtLeast',
                'messageData': {'#1#': 5}
            });
        }
        this.props.makeRequest({perPage:25,'MLSorLicense': this.state.agentMLSorLicense}, methodData).then(function (response) {
            this.setState({
                searchMade: true,
                agentToAdd: {_id: false},
                foundAgents: response.data.data,
                //agentMLSorLicense: ""
            })
        }.bind(this));
    }

    onAgentSearchInputKeyDown(event) {

        switch (event.keyCode) {
            case 9:   // TAB
                // Extend default TAB behavior by doing something here
                break;
            case 13: // ENTER
                // Override default ENTER behavior by doing stuff here and then preventing default
                event.preventDefault();
                this.searchAgents();
                break;
            default:

        }
    }
    componentDidMount() {
        this.fetchTermsOfServiceLink()

    }
    fetchTermsOfServiceLink() {
        this.props.makeRequest({Name:'Documents', type:'TermsOfServiceLink'}, {
            "endpoint": "/get-settings-data"
        }).then(function (res) {

            this.setState({
                TermsOfServiceLink: _.get(res.data, 'data.TermsOfServiceLink.FileURL','')
            });

        }.bind(this));
    }
    goToNextScreen(currentScreen, nextScreen) {
        if (currentScreen == 'add_agents') {
            if (_.size(this.state.selectedAgents) == 0) {
                return this.props.displayMessage({
                    'messageType': 'warning',
                    'messageCode': 'errorAtLeastOneRequired',
                    'messageData': {'#1#': 'professional'},
                });
            }
            this.setState({RolesTabEnabled: true}, function () {
                this.handleTabSelect(nextScreen)
            })

        } else if (currentScreen == '/roles') {

            var notValidEmails = _.filter(this.state.selectedAgents, function (agent, index) {
                if ((agent.Email == '' || !validateEmail(agent.Email)) && agent._id !== this.state.currentUserAgent  ) { /* do not check email for current user agent */
                    agent.emailNotValid = true;
                    return true
                }
            }.bind(this))

            var accountAdministrators = _.filter(this.state.selectedAgents, function (agent) {
                return _.includes(agent.AgentRoles, 'AccountAdministrator')
            })


            if (_.size(notValidEmails) > 0) {
                this.setState({displayErrorsRolesTab: true});
                return this.props.displayMessage({
                    'messageType': 'warning',
                    'messageCode': 'errorAgentEmailNotValid',
                    messageData: {'#1': _.size(notValidEmails) > 1 ? 'addresses' : 'address'}
                });
            }
            if (_.size(accountAdministrators) == 0 && this.state.currentUserAgent !== 'notAnAgent') {
                this.setState({displayErrorsRolesTab: true});
                return this.props.displayMessage({
                    'messageType': 'warning',
                    'messageCode': 'errorAccountAdminRoleNotSpecified'
                });
            }
            this.setState({ContactsTabEnabled: true}, function () {
                this.handleTabSelect(nextScreen)
            })
        }
    }

    changeAgentAdminRole(currentAgent, isAdmin) {
        console.log('in change',isAdmin );
        var itemIndex = _.findIndex(this.state.selectedAgents, function (agent) {
            return currentAgent._id == agent._id;
        });
        if (isAdmin) {
            var updatedRoles = _.union(this.state.selectedAgents[itemIndex].AgentRoles, ['AccountAdministrator'])
        } else {
            var updatedRoles = _.without(this.state.selectedAgents[itemIndex].AgentRoles, 'AccountAdministrator')
        }
        this.setState({
            selectedAgents: [...this.state.selectedAgents.slice(0, itemIndex),
                {...this.state.selectedAgents[itemIndex], rolesNotSpecified: false, AgentRoles: updatedRoles},
                ...this.state.selectedAgents.slice(itemIndex + 1)]
        });
    }
    addProfessionalData(professionalData){
        console.log('professionalData',professionalData );
        if(!professionalData.MLS && !professionalData.License){
            return this.props.displayMessage({
                'messageType': 'warning',
                'messageCode': 'errorOneOfTwoFieldsRequired',
                'messageData': {'#1#': 'MLS', '#2#':'License'}
            });
        }
        if (_.findIndex(this.state.selectedAgents, function (agent) {
                return professionalData.Email === agent.Email || professionalData.MLS === agent.MLS || professionalData.License === agent.License
            }.bind(this)) === -1) {
            this.setState({selectedAgents : this.state.selectedAgents.concat([{
                ...professionalData,
                AgentRoles: ['Agent'],
            }])})

        }
        this.setState({  showAddProfessionalModal:false})
    }
    changeAgentEmail(currentAgent, e) {
        var itemIndex = _.findIndex(this.state.selectedAgents, function (agent) {
            return currentAgent._id == agent._id;
        });
        this.setState({
            selectedAgents: [...this.state.selectedAgents.slice(0, itemIndex),
                {...this.state.selectedAgents[itemIndex], Email: e.target.value},
                ...this.state.selectedAgents.slice(itemIndex + 1)]
        });
    }

    hideNotValidEmailError(currentAgent) {
        var itemIndex = _.findIndex(this.state.selectedAgents, function (agent) {
            return currentAgent._id == agent._id;
        });
        this.setState({
            selectedAgents: [...this.state.selectedAgents.slice(0, itemIndex),
                {...this.state.selectedAgents[itemIndex], emailIsDuplicated: false, emailNotValid: false},
                ...this.state.selectedAgents.slice(itemIndex + 1)]
        });
    }

    validateFormData() {
        var messageData = false, formData = {}, errorFields = {};
        if (!this.state.AgreeToTerms) {
            return {
                messageData: {
                    'messageType': 'warning',
                    'messageCode': 'errorAccountAcceptTermsOfService'
                }, formData
            };
        }


        var requiredFields = ['AgencyName', 'OfficeMLS', 'OfficeName', 'AccountName', 'OfficeStreet', 'OfficePhone', 'OfficeZip', 'OfficeCity'];

        _.forEach(requiredFields, function (value) {
            formData[value] = this.state[value];
            if (! formData[value]) {
                errorFields[value] = true;
                messageData = {
                    'messageType': 'warning',
                    'messageCode': 'errorFieldsRequired'
                }
            }
        }.bind(this));

        if (!this.state.OfficeState.value) {
            errorFields['OfficeState'] = true;
            messageData = {
                'messageType': 'warning',
                'messageCode': 'errorFieldsRequired'
            }
        }
        formData['TeamName'] = this.state['TeamName'];
        this.setState({errors: errorFields});
        return {messageData, formData};
    }

    createAccount() {
        this.setState({pending: true})
        var {messageData, formData} = this.validateFormData();
        if (messageData) {
            this.setState({pending: false})
            return this.props.displayMessage(messageData);
        }
        formData['OfficeAddress'] = {
            Street: formData.OfficeStreet,
            State: this.state.OfficeState.value,
            City: formData.OfficeCity,
            Zip: formData.OfficeZip,
        }
        formData['accountAgents'] = _.map(this.state.selectedAgents, function (agent, index) {
            var dataToSave = {
                FirstName: agent.FirstName,
                LastName: agent.LastName,
                MLS: agent.MLS,
                License: agent.License,
                Roles: agent.AgentRoles
            };
            if (this.state.currentUserAgent == agent._id) {
                dataToSave.UserID = this.props.User.user._id
            }
            dataToSave.Emails = agent.Email ? [agent.Email] : [];
            return dataToSave
        }.bind(this))
         if (this.state.currentUserAgent == 'notAnAgent') {
            formData['UserNotAnAgent'] = true;
        }
        var methodData = {
            messageData: {'#1#': 'Account'},
            messageCode: 'recordAdded',
            endpoint: '/accounts',
            type: 'post'
        }
        this.props.makeRequest(formData, methodData).then(function (response) {
            var userRoles = this.state.currentUserAgent == 'notAnAgent' ? ['AccountAdministrator'] : ['Agent', 'AccountAdministrator']
            this.props.dispatchAction({
                token: response.data.token,
                user: {
                    ...this.props.User.user, AccountID: response.data.account._id,
                    Roles: _.union(this.props.User.user.Roles, userRoles)
                }
            }, UPDATE_USER_DATA);
            console.log('in update user data');
            setTimeout(function () {
                this.props.history.push('/getstarted/billing-details');
              //  this.setState({pending: false})
            }.bind(this), 3500)
            /* to allow update redux state */
        }.bind(this))
            .catch(function (err) {
                this.setState({pending: false})
                var notValidEmail = _.get(err, ['response', 'data', 'email'], false);
                if (notValidEmail) {
                    var selectedAgents = _.cloneDeep(this.state.selectedAgents)
                    _.forEach(selectedAgents, function (agent, index) {
                        if (agent.Email == notValidEmail) {
                            agent.emailIsDuplicated = true;
                            return true
                        }
                    })
                    this.setState({displayErrorsRolesTab: true, selectedAgents: selectedAgents})
                }
            }.bind(this));
    }

    deleteAgentFromList(row) {
        var itemIndex = _.findIndex(this.state.selectedAgents, function (agent) {
            return row._id == agent._id;
        });
        if (this.state.selectedAgents[itemIndex]._id == this.state.currentUserAgent) {
            this.setState({currentUserAgent: 'notAnAgent'});
        }
        this.setState({
            selectedAgents: [...this.state.selectedAgents.slice(0, itemIndex),
                ...this.state.selectedAgents.slice(itemIndex + 1)]
        });
    }

    handleTabSelect(key) {
        if (key == '/roles' && this.state.RolesTabEnabled) {
            this.setState({activeTab: key})
        } else if (key == '/contacts' && this.state.ContactsTabEnabled) {
            this.setState({activeTab: key})
        } else if (key == '') {
            this.setState({activeTab: key})
        }
        // this.props.history.push('/account/office' + key);
    };

    addAgent() {

        if (this.state.agentToAdd._id) {
            var updateStateData = {
                 AgencyName: this.state.agentToAdd.AgencyName,
                OfficeMLS: this.state.agentToAdd.MLS,
                OfficeStreet: this.state.agentToAdd.OfficeAddress,
                OfficeName: this.state.agentToAdd.OfficeName,
                AccountName: this.state.agentToAdd.OfficeName,
                OfficeZip: this.state.agentToAdd.OfficeZip,
                OfficeCity: this.state.agentToAdd.OfficeCity,
                OfficePhone: this.state.agentToAdd.PhoneNumber,
            };
            if (this.state.agentToAdd.OfficeState) {
                updateStateData.OfficeState = {
                    'value': this.state.agentToAdd.OfficeState,
                    'label': States[this.state.agentToAdd.OfficeState]
                }
            }


            if (_.findIndex(this.state.selectedAgents, function (agent) {
                    return this.state.agentToAdd._id === agent._id;
                }.bind(this)) === -1) {
                if(!this.state.agentToAdd.MLS && !this.state.agentToAdd.License){
                    return this.props.displayMessage({
                        'messageType': 'warning',
                        'messageCode': 'errorOneOfTwoFieldsRequired',
                        'messageData': {'#1#': 'MLS', '#2#':'License'}
                    });
                }
                updateStateData.selectedAgents = this.state.selectedAgents.concat([{
                    ...this.state.agentToAdd,
                    AgentRoles: ['Agent'],
                }])
                this.setState(updateStateData);

            }
        }

    }

    displayAdministrators() {
        var administrators = _.filter(this.state.selectedAgents, function (agent) {
            return _.includes(agent.AgentRoles, 'AccountAdministrator')
        });
        if (this.state.currentUserAgent == 'notAnAgent') {
            administrators.unshift({
                'FirstName': this.props.User.user.FirstName,
                'LastName': this.props.User.user.LastName
            })
        }
        var returnString = '';
        if (_.size(administrators) > 0) {
            if (_.size(administrators) > 0) {
                returnString = 'Administrators:';
                +_.forEach(administrators, function (administrator) {
                    returnString += ' ' + administrator.FirstName + ' ' + administrator.LastName + ';'
                });
            } else {
                returnString = 'Administrators: ' + administrators[0].FirstName + ' ' + administrators[0].LastName + ';'
            }
        }
        return returnString
    }

    customActionsFormatter(cell, row) {
        return (
            <div className="deleteAgentButton">
                <FaTimesCircle className="deleteButton"
                               onClick={this.deleteAgentFromList.bind(this, row)}></FaTimesCircle>
            </div>
        );
    }

    onChangeOfficeState(selectedOption) {
        this.setState({'OfficeState': selectedOption, errors: {...this.state.errors, OfficeState: false}});
    }

    displayTabTitle(number, text) {
        return <span><span className="tab-title-number">{number}</span>{text}</span>
    }

    renderOptions() {
        return _.map(this.state.foundAgents, function (agent, index) {

            return (
                <div key={agent._id}
                     className={ classNames('agents-options-list__row', {'--selected': agent._id == this.state.agentToAdd._id}) }
                     onClick={() => this.setState({agentToAdd: agent})}
                >
                     <span
                         className="agents-options-list__name"> {agent.FirstName + (agent.LastName ? ' ' + agent.LastName : '') + ' '  } </span>

                    <span
                        className="agents-options-list__mls"> {'MLS ID: ' + (agent.MLS ? agent.MLS : 'n/a')} </span>

                     <span className="agents-options-list__mls"
                    > {'License #: ' + (agent.License ? agent.License : 'n/a')} </span>

                    {agent.RMDLicense &&
                    <span className="agents-options-list__mls"
                    > {'RMD License #: ' + agent.RMDLicense} </span>
                    }

                </div>
            );
        }.bind(this))
    }

    render() {
        console.log('Create Office Account state',   this.state);
        return (
            <div className="create-account-page create-office-account-page tabs-container">
                <div className="page-title">Create Account For Full Access</div>
                <Tabs defaultActiveKey={''} activeKey={this.state.activeTab} id='create-office-account-tabs'
                      onSelect={(e) => {
                      }} className="tabs-container__tab-list ">
                    {/*unmountOnExit - do not render component until the tab is clicked*/}
                    <Tab eventKey={''} unmountOnExit={true}
                         title={this.displayTabTitle(1, 'Add professional(s) Information')}>
                        <Panel className="add-agent-box">
                            <Row>
                                <Col   xs={12} className="bot20">
                                    <Button onClick={()=>this.setState({showAddProfessionalModal:true})}
                                            className="pull-right btn btn-primary" type="button">
                                        Add Professional
                                    </Button>
                                </Col>
                            </Row>
                            <FormGroup>
                                <InputGroup>
                                    <FormControl type="text"
                                                 defaultValue={''}
                                                 value={this.state.agentMLSorLicense}
                                                 onKeyDown={this.onAgentSearchInputKeyDown.bind(this) }
                                                 onChange={(e) => this.setState({agentMLSorLicense: e.target.value}) }
                                                 placeholder="Enter Professional MLS # or License #"/>
                                    <InputGroup.Addon className="btn btn-primary add-agent-box__search-button"
                                                      onClick={this.searchAgents.bind(this)}>Search
                                        professional</InputGroup.Addon>
                                </InputGroup>
                            </FormGroup>
                            <Well className="add-agent-box__selected-agent-wrapper">
                                { this.state.searchMade ?
                                    <div className="mg-auto size20 bot7" >{_.size(this.state.foundAgents) == 0 ? '':  'We found ' + (_.size(this.state.foundAgents)==1 ? 'a professional' :'professionals') +  ' for you!' }
                                         </div> : ''}
                                    <div className="add-agent-box__agent-select-box">
                                    <div className="panel-heading">
                                        { this.state.searchMade ?
                                            <div className="">
                                                {_.size(this.state.foundAgents) ?
                                                    <div className="agents-options-list">
                                                        { this.renderOptions()}
                                                    </div>
                                                    :
                                                    <span>No record is found. Don't worry, if you need to add more professionals later you will be able to do so.</span>
                                                }
                                            </div> : ''}


                                    </div>
                                    <div
                                        onClick={  this.addAgent.bind(this)}
                                        className="panel-footer btn green">
                                        <div className="text-center top5"><FaPlusCircle
                                            className="text-center"></FaPlusCircle></div>
                                        <div className="text-center font-size13 top5 ">Add Professional</div>
                                    </div>
                                </div>
                            </Well>
                            <div className="left15 right15  add-agent-box__selected-agent-wrapper">
                                <BootstrapTable data={this.state.selectedAgents} ref='table'
                                                condensed={true}
                                                striped={true}
                                                options={{
                                                    noDataText: 'No professional added yet'
                                                }}
                                >
                                    <TableHeaderColumn width="150px" dataField='FirstName' dataAlign="center"
                                                       dataSort={true}>First Name</TableHeaderColumn>
                                    <TableHeaderColumn width="150px" dataField='LastName' dataAlign="center"
                                                       dataSort={true}>Last Name</TableHeaderColumn>
                                    <TableHeaderColumn width="150px" dataField="_id" isKey={true} hidden={true}
                                                       autoValue={ true }
                                                       hiddenOnInsert>Id</TableHeaderColumn>
                                    <TableHeaderColumn width="150px" dataField='MLS' dataAlign="center"
                                                       dataSort={true}>MLS ID</TableHeaderColumn>
                                    <TableHeaderColumn width="150px" dataField='License' dataAlign="center"
                                                       dataSort={true}>License</TableHeaderColumn>
                                    <TableHeaderColumn width="70px" dataAlign="center"
                                                       dataFormat={ this.customActionsFormatter.bind(this) }></TableHeaderColumn>
                                </BootstrapTable>
                                <Row className="top20">
                                    <FormGroup className="pull-right">
                                        <Button onClick={this.goToNextScreen.bind(this, 'add_agents', '/roles')}
                                                className="pull-right btn btn-primary" type="button">
                                            Continue
                                        </Button>
                                    </FormGroup>
                                </Row>
                            </div>
                        </Panel>
                    </Tab>
                    <Tab eventKey={'/roles'} unmountOnExit={true} title={this.displayTabTitle(2, 'Users & Roles')}>

                        {this.state.rolesTabActiveView == 'SelectCurrentUserAgent' &&
                        (<div>
                            <h4 className="top20  pbot10 bold" style={{borderBottom: '3px solid lightcoral'}}>Are you one
                                of these professionals?</h4>

                            <Row className="left20">
                                <Col sm={4} xs={12} className="top10">
                                    <Radio onChange={(e) => this.setState({currentUserAgent: 'notAnAgent'})}
                                           checked={this.state.currentUserAgent == 'notAnAgent'}>
                                        <div className="left4 top2">I am not a professional</div>
                                    </Radio>
                                </Col>
                            </Row>
                            { this.state.selectedAgents.map((agent, rowID) => (
                                <Row className="left20" key={rowID}>
                                    <Col sm={4} xs={12} className="top5">
                                        <Radio onChange={(e) => {
                                            this.changeAgentAdminRole(agent, true)
                                            this.setState({currentUserAgent: agent._id})
                                        }}
                                               checked={this.state.currentUserAgent == agent._id}>
                                            <div className="left4 top2"> {agent.FirstName + ' ' + agent.LastName}</div>
                                        </Radio>
                                    </Col>
                                </Row>
                            ))
                            }
                            <Row className="top20">
                                <Col xs={12}>
                                    <FormGroup className="pull-right">
                                        <Button
                                            onClick={(e) => this.setState({rolesTabActiveView: 'ConfirmRolesForAgents'})}
                                            className="pull-right btn btn-primary" type="button">
                                            Continue
                                        </Button>
                                    </FormGroup>
                                    <FormGroup className="pull-right right20">
                                        <Button onClick={(e) => this.setState({activeTab: ''})}
                                                className="pull-right btn btn-primary" type="button">
                                            Back
                                        </Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>)
                        }
                        {this.state.rolesTabActiveView == 'ConfirmRolesForAgents' &&
                        (<div>
                            <h4 className="top20 pbot10 bold" style={{borderBottom: '3px solid lightcoral'}}>Please
                                ensure all professionals have email address & give admin privileges if needed</h4>
                            { this.state.selectedAgents.map((agent, rowID) => {
                                console.log('agent',agent.AgentRoles );
                                return (
                                    <Row key={rowID}>

                                        <Col sm={4} xs={12} className="top10">
                                            {agent.FirstName + ' ' + agent.LastName  }{agent._id == this.state.currentUserAgent ? ' (logged in user)' : ''}
                                        </Col>


                                        <Col sm={4} xs={12} className="top10">
                                            <FormControl
                                                disabled={agent._id == this.state.currentUserAgent   }
                                                style={this.state.displayErrorsRolesTab && ((!_.isUndefined(agent.emailNotValid) && agent.emailNotValid) || (!_.isUndefined(agent.emailIsDuplicated) && agent.emailIsDuplicated) ) ? {border: '1px solid red'} : {} }
                                                type="text"
                                                defaultValue={agent.Email }
                                                onFocus={this.hideNotValidEmailError.bind(this, agent) }
                                                onChange={ this.changeAgentEmail.bind(this, agent)}
                                                placeholder="Enter Email for Agent"/>
                                        </Col>
                                        <Col sm={4} xs={12} className="top10">

                                            <div style={{justifyContent:'flex-start'}} className={'checkbox-wrap top7'}>
                                                <input className="styled-checkbox"
                                                        disabled={agent._id == this.state.currentUserAgent }
                                                       checked={_.includes(agent.AgentRoles, 'AccountAdministrator')  }
                                                       onChange={_.noop}

                                                       type="checkbox"/>
                                                <label
                                                    disabled={agent._id == this.state.currentUserAgent }
                                                    onClick={e =>{
                                                        if (agent._id == this.state.currentUserAgent){
                                                            return;
                                                        }
                                                         this.changeAgentAdminRole(agent, !e.target.previousElementSibling.checked)
                                                    } }>
                                                    Admin
                                                </label>
                                            </div>

                                        </Col>

                                    </Row>
                                )
                            })
                            }
                            <Row className="top20">
                                <Col xs={12}>
                                    <FormGroup className="pull-right">
                                        <Button onClick={this.goToNextScreen.bind(this, '/roles', '/contacts')}
                                                className="pull-right btn btn-primary" type="button">
                                            Continue
                                        </Button>
                                    </FormGroup>
                                    <FormGroup className="pull-right right20">
                                        <Button
                                            onClick={(e) => this.setState({rolesTabActiveView: 'SelectCurrentUserAgent'})}
                                            className="pull-right btn btn-primary" type="button">
                                            Back
                                        </Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>)
                        }
                    </Tab>
                    <Tab eventKey={'/contacts'} unmountOnExit={true}
                         title={this.displayTabTitle(3, 'Contact Details & Terms')}>
                        <h4 className="top20 pbot10 bold" style={{borderBottom: '3px solid lightcoral'}}>Agency/Office
                            Details</h4>
                        <Row  >
                            <Col sm={6} xs={12} className="top10">
                                <FormControl type="text"
                                             style={this.state.errors['AgencyName'] ? {border: '1px solid red'} : {} }
                                             value={this.state.AgencyName}
                                             onChange={(e) => this.setState({AgencyName: e.target.value})}
                                             onFocus={(e) => this.setState({
                                                 errors: {
                                                     ...this.state.errors,
                                                     AgencyName: false
                                                 }
                                             }) }
                                             placeholder="Agency Name"/>
                                <FormControl className="top10" type="text"
                                             value={this.state.TeamName}
                                             onChange={(e) => this.setState({TeamName: e.target.value})}
                                             placeholder="Team Name"/>
                                <FormControl className="top10"
                                             style={this.state.errors['OfficeMLS'] ? {border: '1px solid red'} : {} }
                                             type="text"
                                             value={this.state.OfficeMLS}
                                             onChange={(e) => this.setState({OfficeMLS: e.target.value})}
                                             onFocus={(e) => this.setState({
                                                 errors: {
                                                     ...this.state.errors,
                                                     OfficeMLS: false
                                                 }
                                             }) }
                                             placeholder="Office MLS#"/>
                            </Col>
                            <Col sm={6} xs={12} className="top10">
                                <FormControl style={this.state.errors['OfficeName'] ? {border: '1px solid red'} : {} }
                                             type="text"
                                             value={this.state.OfficeName}
                                             onChange={(e) => this.setState({OfficeName: e.target.value})}
                                             onFocus={(e) => this.setState({
                                                 errors: {
                                                     ...this.state.errors,
                                                     OfficeName: false
                                                 }
                                             }) }
                                             placeholder="Office Name"/>
                                <FormControl className="top10" style={this.state.errors['AccountName'] ? {border: '1px solid red'} : {} }
                                             value={this.state.AccountName}
                                             onChange={(e) => this.setState({AccountName: e.target.value})} type="text"
                                             onFocus={(e) => this.setState({
                                                 errors: {
                                                     ...this.state.errors,
                                                     AccountName: false
                                                 }
                                             }) }
                                             placeholder="Account Name"/>
                                <FormControl className="top10"
                                             style={this.state.errors['OfficeStreet'] ? {border: '1px solid red'} : {} }
                                             type="text"
                                             value={this.state.OfficeStreet}
                                             onChange={(e) => this.setState({OfficeStreet: e.target.value})}
                                             onFocus={(e) => this.setState({
                                                 errors: {
                                                     ...this.state.errors,
                                                     OfficeStreet: false
                                                 }
                                             }) }
                                             placeholder="Office Address"/>
                                <Row >
                                    <Col sm={4} xs={12} className="top10">
                                        <FormControl
                                            style={this.state.errors['OfficeCity'] ? {border: '1px solid red'} : {} }
                                            type="text"
                                            value={this.state.OfficeCity}
                                            onChange={(e) => this.setState({OfficeCity: e.target.value})}
                                            onFocus={(e) => this.setState({
                                                errors: {
                                                    ...this.state.errors,
                                                    OfficeCity: false
                                                }
                                            }) }
                                            placeholder="Office City"/>
                                    </Col>
                                    <Col sm={4} xs={12} className="top10">
                                        <FormGroup>
                                            <Select
                                                tabIndex="0"
                                                style={this.state.errors['OfficeState'] ? {border: '1px solid red'} : {} }
                                                onFocus={(e) => this.setState({
                                                    errors: {
                                                        ...this.state.errors,
                                                        OfficeState: false
                                                    }
                                                }) }

                                                searchable={true}
                                                multi={false}
                                                selectedOptions
                                                clearable={false}
                                                placeholder="Office State"
                                                options={stateSelectOptions}
                                                onChange={ this.onChangeOfficeState.bind(this)}
                                                value={this.state.OfficeState}
                                                //className={'form-control'}
                                            />
                                        </FormGroup>

                                    </Col>
                                    <Col sm={4} xs={12}>
                                        <FormControl
                                            style={this.state.errors['OfficeZip'] ? {border: '1px solid red'} : {} }
                                            className="top10" type="text"
                                            value={this.state.OfficeZip}
                                            onChange={(e) => this.setState({OfficeZip: e.target.value})}
                                            onFocus={(e) => this.setState({
                                                errors: {
                                                    ...this.state.errors,
                                                    OfficeZip: false
                                                }
                                            }) }
                                            placeholder="Office Zip"/>
                                    </Col>
                                </Row>
                                <FormControl style={this.state.errors['OfficePhone'] ? {border: '1px solid red'} : {} }
                                             className="top10" ref="OfficePhone" type="text"
                                             value={this.state.OfficePhone}
                                             onChange={(e) => this.setState({OfficePhone: e.target.value})}
                                             onFocus={(e) => this.setState({
                                                 errors: {
                                                     ...this.state.errors,
                                                     OfficePhone: false
                                                 }
                                             }) }
                                             placeholder="Office Phone"/>
                            </Col>
                        </Row>
                        <Row className="top20">
                            <Col xs={12} className="top10">
                                <ControlLabel className="ptop5">
                                    { this.displayAdministrators()}

                                </ControlLabel>
                                <div style={{justifyContent:'flex-start'}}  className={'checkbox-wrap top10'}>
                                    <input className="styled-checkbox --inline"
                                            checked={this.state.AgreeToTerms}
                                           onChange={_.noop}

                                           type="checkbox"/>
                                    <label
                                        onClick={e => {
                                            this.setState({AgreeToTerms: !this.state.AgreeToTerms})
                                        } }>
                                        I agree to the <a target="_blank" href={ _.get(this.state,'TermsOfServiceLink','') }>terms of service</a>
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <Row className="">
                            <Col xs={12} className="top10">
                                <FormGroup className="pull-right">
                                    <Button onClick={this.createAccount.bind(this)}
                                            disabled={!this.state.AgreeToTerms || this.state.pending}
                                            className="pull-right btn btn-primary" type="button">
                                        Submit
                                    </Button>
                                </FormGroup>
                                <FormGroup className="pull-right right20">
                                    <Button onClick={(e) => this.setState({activeTab: '/roles'})}
                                            className="pull-right btn btn-primary" type="button">
                                        Back
                                    </Button>
                                </FormGroup>
                            </Col>
                        </Row>

                    </Tab>
                </Tabs>
                <AddProfessionalModal
                    addProfessionalData={this.addProfessionalData.bind(this)}
                    showModal = {this.state.showAddProfessionalModal}
                    displayMessage= {this.props.displayMessage}
                    closeModal={ () => this.setState({
                        showAddProfessionalModal: false
                    })}/>
            </div>
        );

    }
}

function mapStateToProps(state, props) {
    return {
        User: state.userReducer,
        Inventories: state.Inventories,
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({makeRequest, dispatchAction, displayMessage}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateOfficeAccountPage);
