import ReactDOM from 'react-dom';
import React, {Component} from 'react';

export default  class CustomHeaderCheckbox extends React.Component {
    componentDidMount() { this.update(this.props.checked); }
    componentWillReceiveProps(props) { this.update(props.checked); }
    update(checked) {
        ReactDOM.findDOMNode(this).indeterminate = checked === 'indeterminate';
    }
    render() {
        return (
            <input className='styled-checkbox --custom-styled-checkbox'
                    type='checkbox'
                   name={ 'checkbox' + this.props.rowIndex }
                   id={ 'checkbox' + this.props.rowIndex }
                   checked={ this.props.checked }
                   onChange={ this.props.onChange } />
        );
    }
}