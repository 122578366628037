import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Route, Switch} from 'react-router';
import {bindActionCreators} from 'redux';
import {makeRequest, dispatchAction, displayMessage}  from 'actions';
import GuestLayoutHeader from 'common/GuestLayoutHeader';
import AuthenticationPage from 'guests/AuthenticationPage';
import EmailVerificationPage from 'guests/EmailVerificationPage';
import NotFoundPage from 'common/NotFoundPage';
import AccountSelectTypePage from 'accounts/accountCreateUpdate/AccountSelectTypePage';
import CreateAgentAccountPage from 'accounts/accountCreateUpdate/CreateAgentAccountPage';
import AccountBillingDetailSetup from 'accounts/accountCreateUpdate/AccountBillingDetailSetup';
import AccountInventorySetup from 'accounts/accountCreateUpdate/AccountInventorySetup';


import CreateOfficeAccountPage from 'accounts/accountCreateUpdate/CreateOfficeAccountPage';
import ThankYouPage from './ThankYou';

class GuestLayout extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            accessNotAllowed:true
        };

    };
    componentDidMount() {
        this.redirectToPage(this.props)
    }

    componentWillReceiveProps(nextProps) {
        if ( this.props.User.token && !nextProps.User.token) { /* after log out */
             this.props.history.push('/signin');
        }
         if ((nextProps.location.pathname == '' || nextProps.location.pathname == '/') && nextProps.location.pathname !== this.props.location.pathname  ){
            this.redirectToPage(nextProps)
        }
    }
    redirectToPage(properties){
        console.log('properties.location.pathname',properties.location.pathname  );
        if(_.includes(properties.location.pathname, '/email_verification')  || _.includes(properties.location.pathname, '/set_password') ||  _.includes(['/thankyou'], properties.location.pathname)){
            this.setState({accessNotAllowed:false})
        } else if  (properties.User.token){
            console.log('token exists'  );
                if (_.size(_.intersection(properties.User.user.Roles, [ 'CustomerService', 'SuperAdministrator', 'ValetAdministrator', 'PrintAdministrator']))){
                    properties.history.push('/rmd/valet');
                } else  if (_.size(_.intersection(properties.User.user.Roles, [ 'Driver' ]))){
                    properties.history.push('/rmd/orders');
                } else  if(properties.User.user.AccountID && _.size(_.intersection(properties.User.user.Roles, ['AccountAdministrator','Agent']))) {
                    properties.history.push('/cust/valet');
                }  else { /* user do not have associated Account record */
                 console.log('user do not have accountid')
                   // if (_.includes(['/choose-account-type', '/getstarted-professional', '/getstarted-office', '/getstarted/billing-details', '/getstarted/inventory-setup'], properties.location.pathname)) {
                        properties.history.push('/choose-account-type');
                        this.setState({accessNotAllowed:false})
                        /*redirect to choose-account-type, if these pages are opened directly in the browser*/
                  //  }

                }
        } else {
            console.log('token empty'  );
            if(_.includes(['/signin', '/signup'], properties.location.pathname)){
                this.setState({accessNotAllowed:false})
            } else if (_.includes(['/choose-account-type', '/getstarted-professional', '/getstarted-office', '/getstarted/billing-details', '/getstarted/inventory-setup'], properties.location.pathname)) {
                properties.history.push('/choose-account-type');
                /*redirect to choose-account-type, if these pages are opened directly in the browser*/
                this.setState({accessNotAllowed:false})
            } else{
                console.log('in signin'  );
                properties.history.push('/signin', {originalPageURL:properties.location.pathname});
                this.setState({accessNotAllowed:false})
            }

        }

    }
    render() {
        if (this.state.accessNotAllowed){
            return null;
        }
        console.log('in render GuestLayout'  );
         return (
            <div className="guest-layout">
                <GuestLayoutHeader {...this.props } ></GuestLayoutHeader>
                <div className="container">
                    <Switch>
                        <Route path={'/signin'} exact component={AuthenticationPage}/>
                          <Route path={'/signup'} exact component={AuthenticationPage}/>
                        <Route path={"/set_password/:token"} exact component={AuthenticationPage}/>
                        <Route path={"/email_verification/:token"} exact component={EmailVerificationPage}/>
                        <Route path={`/choose-account-type`} component={AccountSelectTypePage}/>
                        <Route path={`/getstarted-professional`} component={CreateAgentAccountPage}/>
                        <Route path={`/getstarted/billing-details`} component={AccountBillingDetailSetup}/>
                         <Route path={`/getstarted/inventory-setup`} component={AccountInventorySetup}/>
                        <Route path={`/getstarted-office`} component={CreateOfficeAccountPage}/>
                        <Route path={`/thankyou`} exact component={ThankYouPage}/>
                        <Route component={NotFoundPage}/>
                    </Switch>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        User: state.userReducer,
        // currentURL: ownProps.location
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({makeRequest, dispatchAction, displayMessage}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(GuestLayout);