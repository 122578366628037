import {DropdownButton,Row,Col, MenuItem} from 'react-bootstrap';
import React from 'react';
import ConfirmModalDialog from'shared/ConfirmModalDialog';
import CustomTableHeaderCheckbox from 'shared/CustomTableHeaderCheckbox';
import {FormControl } from 'react-bootstrap';
import config from  '../../config';
import moment from 'moment'
import classNames from 'classnames'
export function prepareSelectOptionsList(data, fieldsMap) {
    return _.reduce(data, function (optionList, item) {
        var option = {'value': item[fieldsMap['value']], 'label': item[fieldsMap['label']]};
        if (!_.isUndefined(fieldsMap['label2'])) {
            option.label = option.label + (item[fieldsMap['label2']] ? (' ' + item[fieldsMap['label2']]) : '');
        }
        optionList.push(option);
        return optionList;
    }, []);
}
var actionsMenuOffsetMap = {2:'-20px', 3:'-30px', 4:'-40px'}
export function validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}
export function isIE() {
    var ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
    var msie = ua.indexOf('MSIE '); // IE 10 or older
    var trident = ua.indexOf('Trident/'); //IE 11

    return (msie > 0 || trident > 0);
}
export function connectedFieldFormatter(cell, row, extra) {
    return row[extra.record][0][extra.field];
}
export function  base64ToHex(base64) {
    var raw = atob(base64);
    var HEX = '';
    for (var i = 0; i < raw.length; i++ ) {
        var _hex = raw.charCodeAt(i).toString(16)
        HEX += (_hex.length==2?_hex:'0'+_hex);
    }
    return HEX.replace(/^(.{8})(.{4})(.{4})(.{4})(.{12})$/, function (){
        return arguments[1]+"-"+arguments[2]+"-"+arguments[3]+"-"+arguments[4]+"-"+arguments[5];
    });
}
export function loadBase64Image(url, callback) {
    console.log('loadBase64Image', url  );
    var xhr = new XMLHttpRequest();
    try {
        xhr.onload = function () {
           // console.log('onload' ,xhr.response  );
            var reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.onreadystatechange = function () {
///console.log('xhr.readyState ', xhr.readyState   );
            if (xhr.readyState === XMLHttpRequest.DONE) {


            }
        }
        xhr.upload.addEventListener("error", function (e) {
            console.log('in error', e);
            callback(null);
        })
        xhr.upload.addEventListener("abort", function (e) {
            console.log('in abort', e);
            callback(null);
        })
        xhr.onerror == function (err) {
            console.log('onerror', err);
            callback(null);
        }
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }catch (e){
        console.log('catch1', e   );
    }
}

export function  thumbnailFormatter(cell, row,columnIndex, rowIndex, imagePath =[ 'Images'],displayMultipleImages = false) {
     if (_.get(row,_.concat(imagePath, ['0']), false)) {
        var lastRow = false;
        if (_.ceil(this.props.total /  this.props.perPage) == this.props.currentPage+1){/* check if it is the last page */
            if (this.props.total > this.props.perPage){/* if there are more than one page */
                if (this.props.total % this.props.perPage == rowIndex+1){
                     lastRow = true;
                }
            } else  if (this.props.total  == rowIndex+1){
                 lastRow = true;
            }
        }
        if (displayMultipleImages){
            return <div>
                {_.map(_.get(row, imagePath),  (image)=> {
                    var packageIndex = _.findIndex(row.Packages, function (orderPackage) {
                        return  image.PackageID ==orderPackage._id;
                    });
                    return <div key={image._id} className={classNames( 'image-wrapper --multiple-images', {'first-or-last-row':  rowIndex == 0 || rowIndex==(this.props.perPage-1)  || lastRow ? true:false}, {'first-row':  rowIndex == 0 ? true:false}, { 'enlarged' : this.state.activeImageID === image._id })}
                                onClick={() => {
                                    changeActiveImageID.call(this, image._id)
                                }}>
                        <img title={packageIndex == -1 ? '' : 'Package '+(packageIndex+1) } src={image.URL} />
                    </div>;
                })}</div>
        } else {
            return <div
                className={classNames('image-wrapper --multiple-images', {'first-or-last-row': rowIndex == 0 || rowIndex == (this.props.perPage - 1) || lastRow ? true : false}, {'enlarged': this.state.activeImageID === _.get(row, _.concat(imagePath, ['0','_id']))})}
                onClick={() => {
                    changeActiveImageID.call(this, _.get(row, _.concat(imagePath, ['0', '_id'])))
                }}>
                <img src={_.get(row, _.concat(imagePath, ['0', 'URL']), '')}/>
            </div>;
        }
    } else{
        return ''
    }
}

export function displayCustomDialog(params) {
    return <ConfirmModalDialog
        buttonTitle={_.get(params, 'buttonTitle', '')}
        secondButtonTitle={_.get(params, 'secondButtonTitle')}
         cancelButtonTitle={_.get(params, 'cancelButtonTitle')}
        show={ this.state['show'+params.dialogID]}
        dialogContent ={_.get(params, 'dialogContent')}
        title={_.get(params, 'modalTitle', '')}

        cancelActionHandler ={_.get(params, 'cancelActionHandler')}
        confirmButtonDisabled ={params.confirmButtonDisabled}
        confirmActionHandler={(data) =>  {
            this.setState({
                ['show'+params.dialogID]: false,
             }, function () {
                params.confirmActionHandler(data)
            })
        } }

        closeModal={(e) => this.setState({
            ['show'+params.dialogID]: false,
        })}>
    </ConfirmModalDialog>
}
export function displayPayWithAccountCreditCardDialog() {
    return <ConfirmModalDialog
        buttonTitle={_.get(this.state, 'actionModalData.buttonTitle', 'Change credit card options')}
        show={_.get(this.state, 'actionModalData.confirmModalText', '') !== ''}
        title={'Notification'}
        dialogContent={ function ( ) {
            return <Row><Col className="align-text-left bot5 "
                             xs={12}>
                {_.get(this.state, 'actionModalData.confirmModalText', '') }
            </Col>
             </Row>
        }.bind(this)}
         secondButtonTitle={_.get(this.state, 'actionModalData.secondButtonTitle')  }

        confirmActionHandler={(data) =>  {
             if (data.buttonNumber == 1) {
                if (_.get(this.state, 'actionModalData.action') =='use_account_credit_card'){
                    this.setState({
                        actionModalData: {
                            confirmModalText: '',
                        },
                        selectedAgent: {...this.state.selectedAgent, placeOrderUnderAdministratorCreditCard: true}
                    }, function () {
                        this.saveRecord()
                    })
                }else   if (_.get(this.state, 'actionModalData.action') =='close_modal'){
                    this.setState({
                        actionModalData: {
                            confirmModalText: '',
                        },
                     } )
                }else{
                    window.open('/cust/account/billing-details', '_blank');
                }

            } else  if(data.buttonNumber ==2) {
                
            }
        } }

        closeModal={(e) =>   this.setState({
            actionModalData: {
                confirmModalText: '',
            },
         })}>
    </ConfirmModalDialog>
}
  function changeActiveImageID( id) {
      if (this.timeout) {
        clearTimeout(this.timeout)
    }
    console.log('id',id );
    if (this.state.activeImageID ===  id) {
        return this.setState({
            activeImageID: ''
        })
    }
    return this.setState({
        activeImageID: id,
    }, () => this.timeout = setTimeout(() => this.setState({
        activeImageID: ''
    }), 5000))
}

export function actionsFormatter(cell, row, extra,rowIndex) {
    if (_.isArray(extra.menuItemsMap)) {
        var menuItemsList = extra.menuItemsMap;
    } else {
        var currentItem = _.isUndefined(extra.rowField) ? extra.currentItem : row[extra.rowField];
        var menuItemsList = extra.menuItemsMap[currentItem];
    }
    var menuItems = menuItemsList.map((item, itemID) => {
            // console.log('this[item.onClick].bind(this, row)', this[item.onClick].bind(this, row));
            return (<MenuItem key={itemID} eventKey={itemID}
                              onClick={ typeof item.onClick === "string" ?  this[item.onClick].bind(this, row) :item.onClick.bind(this, row) }>{item.label}</MenuItem>)
        }
    );
          return  _.size(menuItemsList) === 0 ? null :  (

            <DropdownButton bsStyle="default"   pullRight
                          //  className={classNames( {'table-last-row':   rowIndex==(this.props.perPage-1) ? true:false}) }
                            onClick={e =>  {
                             //   dropdownPosition(event)
                                 let dropdownMenu = e.target.className === 'glyphicon glyphicon-cog' ? e.target.parentNode.nextSibling : e.target.nextSibling;
                                var lastRow = false;
                                 if (_.get(this.props,'total', 0) <=  _.get(this.props,'perPage', 10000) ) {/* if there is only one page. If perPage is undefined, it means all records are fetched and displayed on one page */
                                    if (this.props.total  == rowIndex+1){
                                        lastRow = true;
                                    }
                                } else if ( _.ceil(_.get(this.props,'total',1) /  _.get(this.props,'perPage', 1)) == _.get(this.props,'currentPage', 100) +1){/* check if it is the last page.*/
                                    if (this.props.total % this.props.perPage == rowIndex+1){
                                        lastRow = true;
                                    }
                                }

                              // if(_.isUndefined(this.props.perPage)){ /* all records are fetched and displayed on one page */
                              //      if (this.props.total  == rowIndex+1){
                              //          lastRow = true;
                              //      }
                              //  }else   if ( _.ceil(_.get(this.props,'total', 0) / this.props.perPage) == _.get(this.props,'currentPage', 10000) +1){/* check if it is the last page.*/
                              //       if (this.props.total > this.props.perPage){/* if there are more than one page */
                              //           if (this.props.total % this.props.perPage == rowIndex+1){
                              //               lastRow = true;
                              //           }
                              //       } else  if (this.props.total  == rowIndex+1){
                              //           lastRow = true;
                              //       }
                              //   }
                                 if (  dropdownMenu.getElementsByTagName("li").length > 1 && (rowIndex==(this.props.perPage-1) ||   lastRow)  ){
                                      var actionsCellElement = getClosest(e.target, '.actions-column');

                                       // if (actionsCellElement.classList.contains("table-last-row")) {
                                      //     //   actionsCellElement.classList.remove("last-row");
                                      //     // dropdownMenu.classList.remove("menu-items-"+dropdownMenu.getElementsByTagName("li").length+"-centered");
                                      // } else {
                                      //     console.log('px',  dropdownMenu.getElementsByTagName("li").length * 10 + 10 +'px'  );
                                      //      dropdownMenu.style.top ='-'+ (dropdownMenu.getElementsByTagName("li").length * 10 + 10) +'px'
                                      //     dropdownMenu.style['padding-top'] =(dropdownMenu.getElementsByTagName("li").length * 10 + 10) +'px'
                                      //     dropdownMenu.style['padding-bottom'] =(dropdownMenu.getElementsByTagName("li").length * 10 + 10) +'px'
                                      //    // actionsCellElement.classList.add("table-last-row","menu-items-" + dropdownMenu.getElementsByTagName("li").length + "-centered");
                                      // }

                                    dropdownMenu.style.top ='-'+ (dropdownMenu.getElementsByTagName("li").length * 11) +'px'
                                    actionsCellElement.style['padding-bottom'] =  actionsCellElement.style['padding-top'] = (dropdownMenu.getElementsByTagName("li").length * 13) +'px'

                                  }
                            }}
                            title={ <span className="glyphicon glyphicon-cog"/>} noCaret key={row.Id}
                            id="dropdown-basic-default">
                {menuItems}
            </DropdownButton>
        );
}
function  getClosest(elem, selector) {

    // Element.matches() polyfill
    if (!Element.prototype.matches) {
        Element.prototype.matches =
            Element.prototype.matchesSelector ||
            Element.prototype.mozMatchesSelector ||
            Element.prototype.msMatchesSelector ||
            Element.prototype.oMatchesSelector ||
            Element.prototype.webkitMatchesSelector ||
            function(s) {
                var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                    i = matches.length;
                while (--i >= 0 && matches.item(i) !== this) {}
                return i > -1;
            };
    }

    // Get the closest matching element
    for ( ; elem && elem !== document; elem = elem.parentNode ) {
        if ( elem.matches( selector ) ) return elem;
    }
    return null;

};
function dropdownPosition(e) {
    let dropdownMenu = e.target.className === 'glyphicon glyphicon-cog' ? e.target.parentNode.nextSibling : e.target.nextSibling;
  //  let cursorLeft = e.clientX;
   // let cursorTop = e.clientY;
     let windowWidth = document.body.offsetWidth; /* e.target.closest('body') e.target.closest is not supported in ie 11 */
    dropdownMenu.style.position = 'fixed';
     var dropdownMenuProps = e.target.parentNode.getBoundingClientRect()

    dropdownMenu.style.top = `${dropdownMenuProps.top-2}px`;
    console.log('right', windowWidth - (dropdownMenuProps.left -1)  );
    dropdownMenu.style.right = `${windowWidth - (dropdownMenuProps.left -1)}px`;
   // dropdownMenu.style.left = 'auto';

 }

export function dateCellFormatter(cell, row, extra) {
     if (_.get(row, extra.path, '')) {
        return moment(_.get(row, extra.path, '')).format(extra.format);
    } else{
        return ''
    }
}
export function stringToTextInputFormatter(cell, row, extra)  {
      if (extra.textarea){
       var formControlPros = {componentClass:'textarea'}
    } else{
        var formControlPros = {type:'text'}
    }
     return (
        <FormControl {...formControlPros}
                     value={row[extra.field]}
                     onChange={(e) => {
                         extra.onChangeHandler( row, e)
                     }}
                     style={row[extra.field +'NotValid']  ? {border: '1px solid red'} : {} }
                      onFocus={(e) => {
                           extra.onFocusHandler && extra.onFocusHandler( row,extra.field )} }
                     placeholder={extra.placeholder}/>
    );
}

export function appendTextFormatter(cell, row, extra) {
    if (extra.type == 'suffix') {
        return _.get(row, extra.path, '') + extra.text
    } else if (extra.type == 'prefix') {
        return extra.text + _.get(row, extra.path, '')
    }
}
export function customFormatter(cell, row, extra) {
     if (extra.path == 'getObject'){
        return extra.format(row)
    } else {
        return extra.format(_.get(row, extra.path, ''), _.get(row, _.get(extra, ['path2'], 'path2_not_available'), undefined))
    }
}

export function onAllRowSelect(isSelected, rows){
    var rowIDs = _.map(rows, '_id')
    if (isSelected) {
        this.setState({recordIDs: _.uniq(this.state.recordIDs.concat(rowIDs))})
    } else {
        this.setState({recordIDs: _.difference(this.state.recordIDs,rowIDs)})
    }
}
export function onRowSelect(row, isSelected, e) {
    if (isSelected) {
        this.setState({recordIDs: this.state.recordIDs.concat([row._id])})
    } else {
        this.setState({recordIDs: _.pull([...this.state.recordIDs], row._id)})
    }
}
export function customMultiSelect(props) {
     return customTableSelect(props, {
        table: _.get(this.refs, 'table.tableRef') ? this.refs.table.tableRef : undefined,
        selectedRows: this.state.recordIDs,
    });
}



export function customTableSelect(props, customData) {
     var pageRowIDs = []
     if (!_.isUndefined(customData.table)) {
         pageRowIDs = _.map(customData.table.state.data, function (row, key) {
            return row._id;
        });
    }
    var {type, checked, disabled, onChange, rowIndex} = props;
    if (_.size(pageRowIDs) ){
        if (rowIndex !== 'Header') {
            checked = _.includes(customData.selectedRows, pageRowIDs[rowIndex])
        } else   if (rowIndex == 'Header'  ) { /* select Header checkbox on initial load */
            if (customData.selectedRows.length >0) { /* if all rows on this page are included in selectedRows, set header checkbox as selected */

 var difference = _.difference(pageRowIDs, customData.selectedRows).length;
                checked = difference ==0 ? true: (difference == pageRowIDs.length ? false: 'indeterminate')
          }else{
              checked= false
          }
         }
    }
     /*
     * If rowIndex is 'Header', means this rendering is for header selection column.
     */
    if (rowIndex === 'Header') {
          return (
            <div className='checkbox-wrap '>
                <CustomTableHeaderCheckbox { ...{...props,  checked:checked} }/>
                <label htmlFor={ 'checkbox' + rowIndex }>
                 </label>
            </div>);
    } else {
        return (
            <div className='checkbox-wrap'>
                <input
                    className='styled-checkbox --custom-styled-checkbox'
                    type={ type }
                    name={ 'checkbox' + rowIndex }
                    id={ 'checkbox' + rowIndex }
                    checked={ checked }
                    disabled={ disabled }
                    onChange={ e => onChange(e, rowIndex) }
                />
                <label htmlFor={ 'checkbox' + rowIndex }>
                 </label>
            </div>);
    }
}
export function getFieldByPathFormatter(cell, row, extra) {
    return _.get(row, extra.path, '');
}




