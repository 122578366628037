import { combineReducers } from 'redux';
import userReducer from './user_reducer';
import accountOrdersReducer from './accounts/account_orders_reducer';
import accountDataReducer from './accounts/account_data_reducer';

import accountsEditReducer from './accounts/accounts_edit_reducer';
import shoppingCartReducer from './accounts/shopping_cart_reducer';


import inventoryReducer from './inventory_reducer';
import inventorySerialsReducer from './inventory_serials_reducer';
import invoiceReducer from './invoice_reducer';
import defaultPricingReducer from './default_pricing_reducer';
import accountPricingReducer from './accounts/account_pricing_reducer';
import adminAccountsReducer from './admin_accounts_reducer';


import emailTypeReducer from './emailType_reducer';

import allAccountsReducer from './all_accounts_reducer';

import AppDataReducer from './app_data_reducer';

import ordersReducer from './orders_reducer'

const rootReducer = combineReducers({
  userReducer: userReducer,
  accountOrdersReducer:accountOrdersReducer,
  inventoryReducer:inventoryReducer,
  inventorySerialsReducer:inventorySerialsReducer,
  appDataReducer: AppDataReducer,
  accountDataReducer:accountDataReducer,
  allAccountsReducer: allAccountsReducer,
  accountsEditReducer: accountsEditReducer,
  invoiceReducer: invoiceReducer,
  defaultPricingReducer: defaultPricingReducer,
  accountPricingReducer: accountPricingReducer,
  emailTypeReducer: emailTypeReducer,
    shoppingCartReducer:shoppingCartReducer,
  ordersReducer: ordersReducer,
  adminAccountsReducer: adminAccountsReducer
});

export default rootReducer;