import {   DELETE_ACCOUNT_PRICING, ADD_ACCOUNT_PRICING,  UPDATE_ACCOUNT_PRICING, FETCH_ACCOUNT_PRICING,USER_LOGOUT }  from "actions/actionTypes";

var _ = require('lodash');

const initialState = {data: [], fetched:false   };
export default function (state = initialState, action) {
    switch (action.type) {

        case FETCH_ACCOUNT_PRICING:
            console.log(FETCH_ACCOUNT_PRICING, action.payload);
            return {
                data: action.payload.data,
                fetched:true,
            }
        case UPDATE_ACCOUNT_PRICING:
            console.log(UPDATE_ACCOUNT_PRICING, action.payload);
            var itemIndex = _.findIndex(state.data, {'_id': action.payload._id});
            if (itemIndex !== -1) {
                var updatedRecords = [...state.data.slice(0, itemIndex),
                    action.payload,
                    ...state.data.slice(itemIndex + 1)];
                return {
                    ...state,
                    data: updatedRecords
                }
            } else{
                return state
            }
        case ADD_ACCOUNT_PRICING:
            console.log('ADD_ACCOUNT_PRICING', state, action.payload);
            return {
                ...state,
                data: _.concat(state.data, [action.payload]),
             }
        case DELETE_ACCOUNT_PRICING:
            console.log('DELETE_ACCOUNT_PRICING', state, action.payload);
            var itemIndex = _.findIndex(state.data, {'_id': action.payload._id});
            if (itemIndex == -1){
                return state;
            }
            return {
                ...state,
                data: [...state.data.slice(0, itemIndex),
                    ...state.data.slice(itemIndex + 1)]
            }
        case USER_LOGOUT:
            return initialState;
        default:
            return state;
    }
}
