import {USER_LOGOUT, FETCH_ORDERS,UPDATE_ORDER}  from "actions/actionTypes";

var _ = require('lodash');

const initialState = {data: [], total:0, fetched:false};
export default function (state = initialState, action) {
    console.log('action', action );
  switch (action.type) {
    case FETCH_ORDERS:
      console.log('FETCH_ORDERS', action, action.payload);
      return {
        data: action.payload.data,
        total: action.payload.total,
        fetched:true,
      }
      case UPDATE_ORDER:
          console.log('UPDATE_ORDER', state, action.payload);
          var itemIndex = _.findIndex(state.data, {'_id': action.payload._id});

          var updatedRecords = [...state.data.slice(0, itemIndex),
              action.payload,
              ...state.data.slice(itemIndex + 1)];
          return {
              ...state,
              data: updatedRecords
          }
      case USER_LOGOUT:
          return initialState;
    default:
      return state;
  }
}
