import React, {Component} from 'react';
import {connect} from 'react-redux';
import {UPDATE_USER_DATA} from "actions/actionTypes";
import {makeRequest, displayMessage}  from 'actions';

class EmailVerificationPage extends Component {

    constructor(props) {
        super(props)
        this.state = {pending: false}
    }

    componentDidMount() {
        if (this.props.match.params.token) {
            this.checkEmailVerificationToken({token: this.props.match.params.token})
        }
    }

    checkEmailVerificationToken(params) {
        var self = this;
        var methodData = {
            "success_event": UPDATE_USER_DATA,
            'endpoint': '/confirm_email_verification',
            messageCode: 'emailConfirmed',
            type: 'post'
        };
        self.props.makeRequest(params, methodData).then(function (response) {
            self.props.history.push('/choose-account-type');
        }).catch(function (error) {
        });
    }

    render() {
        return null;
    }
}
function mapStateToProps(state) {
    return {}
}
export default connect(mapStateToProps, {makeRequest, displayMessage})(EmailVerificationPage);
