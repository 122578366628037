import { USER_LOGOUT, FETCH_ALL_INVOICES }  from "actions/actionTypes";

var _ = require('lodash');

const initialState = {data: [], fetched:false };
export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_ALL_INVOICES:
            console.log(FETCH_ALL_INVOICES, action.payload);
            console.log(action)
            return {
                fetched:true, 
                data: action.payload.data,
                total: action.payload.total
            }
        case USER_LOGOUT:
            return initialState;
        default:
            return state;
    }
}
