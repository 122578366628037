export var States = {
    "AL": "Alabama",
    "AK": "Alaska",
    "AS": "American Samoa",
    "AZ": "Arizona",
    "AR": "Arkansas",
    "CA": "California",
    "CO": "Colorado",
    "CT": "Connecticut",
    "DE": "Delaware",
    "DC": "District Of Columbia",
    "FM": "Federated States Of Micronesia",
    "FL": "Florida",
    "GA": "Georgia",
    "GU": "Guam",
    "HI": "Hawaii",
    "ID": "Idaho",
    "IL": "Illinois",
    "IN": "Indiana",
    "IA": "Iowa",
    "KS": "Kansas",
    "KY": "Kentucky",
    "LA": "Louisiana",
    "ME": "Maine",
    "MH": "Marshall Islands",
    "MD": "Maryland",
    "MA": "Massachusetts",
    "MI": "Michigan",
    "MN": "Minnesota",
    "MS": "Mississippi",
    "MO": "Missouri",
    "MT": "Montana",
    "NE": "Nebraska",
    "NV": "Nevada",
    "NH": "New Hampshire",
    "NJ": "New Jersey",
    "NM": "New Mexico",
    "NY": "New York",
    "NC": "North Carolina",
    "ND": "North Dakota",
    "MP": "Northern Mariana Islands",
    "OH": "Ohio",
    "OK": "Oklahoma",
    "OR": "Oregon",
    "PW": "Palau",
    "PA": "Pennsylvania",
    "PR": "Puerto Rico",
    "RI": "Rhode Island",
    "SC": "South Carolina",
    "SD": "South Dakota",
    "TN": "Tennessee",
    "TX": "Texas",
    "UT": "Utah",
    "VT": "Vermont",
    "VI": "Virgin Islands",
    "VA": "Virginia",
    "WA": "Washington",
    "WV": "West Virginia",
    "WI": "Wisconsin",
    "WY": "Wyoming"
}

export var imageLinks = ['http://s3-us-west-1.amazonaws.com/upsigndown/liveusd/sign/templates/preview/thumbnail_ec461c561a5dead862d85be48b7f7750.png',
    'http://s3-us-west-1.amazonaws.com/upsigndown/liveusd/sign/templates/preview/thumbnail_df361bcca48af6780543f387779f58e4.png',
    'http://s3-us-west-1.amazonaws.com/upsigndown/liveusd/sign/templates/preview/thumbnail_7ce966f2b330442685b608b0f142826f.png',
    'http://s3-us-west-1.amazonaws.com/upsigndown/liveusd/item/tall_signpost1486424299.jpg',
    'https://rfclipart.com/image/preview/d0-40-38/signpost-Download-Royalty-free-Vector-File-EPS-2348.jpg',
    'http://s3-us-west-1.amazonaws.com/upsigndown/liveusd/item/1489427174649.jpg',
    'http://malayasigns.com/dev2014/wp-content/gallery/gallery/broadway-center-apartments-entry-sign.jpg']