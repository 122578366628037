 import {USER_LOGOUT,
     FETCH_INVENTORIES_SERIALS, ADD_INVENTORY, CLEAR_INVENTORY_SERIALS}  from "actions/actionTypes";

var _ = require('lodash');

const initialState = {data: [], fetched:false,  total: 0,  selectedRecords:[]};
export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_INVENTORIES_SERIALS:
          console.log('FETCH_INVENTORIES_SERIALS', action, action.payload);
            return {
                ...state,
                fetched:true,
                data: action.payload.data,
                total: action.payload.total,
            }
        case CLEAR_INVENTORY_SERIALS:
            return initialState;
        case USER_LOGOUT:
            return initialState;
        default:
            return state;
    }
}

export const getItem = (state, id) => {
     return state.inventorySerialsReducer.data.filter(record => record._id === id)[0];
}