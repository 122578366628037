import axios from 'axios';
import config from  '../../config';
import {USER_LOGOUT,UPDATE_APP_DATA} from "actions/actionTypes";
axios.defaults.timeout = 120001;

export function dispatchAction(payload, actionName) {
    return (dispatch, getState) => {
         dispatch({
            type: actionName,
            payload: payload
        })
    }
}

export function makeRequest(data, methodData) {
    return (dispatch, getState) => {
     //   console.log('getState',getState() );
        var requestPromise;
        let token = getState().userReducer.token;
          methodData['getState'] = getState;

        var params = {
            baseURL: config.getEnvironment().serverBaseURL,
            headers: {'x-access-token': token}
        }
        if (!_.isUndefined(methodData.timeout)){
            params.timeout = methodData.timeout
        }
        if (_.isUndefined(methodData.type)){

            requestPromise =   axios.get(methodData['endpoint'], {
                ...params,
                params: data,
            });
        } else if (methodData.type == 'post') {

           if (_.isUndefined(methodData.timeout)){
              // params.timeout = 30002; /* in milliseconds */
             }
            console.log('params', params  );
           console.log('endpoint', methodData['endpoint']  );
             requestPromise = axios.post(methodData['endpoint'], data,params)
        } else if (methodData.type == 'patch') {

            requestPromise = axios.patch(methodData['endpoint'], data, params);
        }else if (methodData.type == 'delete') {

            requestPromise =  axios.delete(methodData['endpoint'],  {
                ...params,
                params: data,
            })
        }
        return requestPromise.then(response => {
             if (typeof response.data == 'string' && response.data.includes('<!DOCTYPE html>')) {
                throw  {'clientMessage': 'errorSomethingWrong'};
            }
             if (!_.isUndefined(methodData['messageCode'])) {
                 _displayMessage(methodData);
            }
             if (!_.isUndefined(methodData['success_event'])) {
                var payload = {
                    ...response.data
                };
                if (!_.isUndefined(methodData['success_event_data'])) {
                    _.assign(payload, methodData['success_event_data']);
                }
                dispatch({
                    type: methodData['success_event'],
                    payload: payload
                })
            }
            return response;
        })
            .catch(error => {
                console.log('in error', error);
                return handleError({error,methodData,dispatch})
            })
    }
}





export function handleError(params) {
    console.log('in handle error', params.error);
    return  new Promise ((resolve, reject) => {
        // if ( _.get(params, ['error', 'response', 'status'], false) == 500 && _.get(params, ['error', 'response', 'data'], '').includes('The request timed out')) {
        //     _displayMessage({
        //         ...params.methodData,
        //         messageType: 'warning',
        //         messageCode:  'timeoutRequestExceeded'
        //     });
        //
        // } else
        if (_.isUndefined(params.error.response)) {
            if ( params.error.message == 'Network Error') {
                _displayMessage({ ...params.methodData,messageType: 'warning', messageCode: 'errorConnection'});
            }else if   ( _.get(params, ['error', 'message'], '').includes('timeout') && _.get(params, ['error', 'message'], '').includes('exceeded')  ) {
               console.log('message', _.get(params, ['error', 'message'], '') , _.get(params,  ['error', 'config', 'url'],'url n/a') );
                _displayMessage({ ...params.methodData,messageType: 'warning', messageCode: 'timeoutRequestExceeded'});
             }  else{ /* this is javascript error on client side */
                console.warn('error message', params.error.message);
            }
            reject(params.error);
        }else if(config.apiErrorCodeMap[getErrorCode(params.error)] == 'AccessTokenNotValid' || config.apiErrorCodeMap[getErrorCode(params.error)] == 'TokenExpired' ) {
            console.log('log out and redirect to log in' );
            params.dispatch({
                type: USER_LOGOUT
            })
            reject(params.error);
        } else{
            console.log('params.error', params.error  );
            if (_.isUndefined(params.methodData['not_display_error_message']) && _.isUndefined(_.get(params.error,'response.data.doNotDisplayClientMessage'))) {
                _displayMessage({
                    ...params.methodData,
                    messageType: 'warning',
                    messageCode: getErrorMessage(params.error, params.defaultMessage)
                });
            }
            reject(params.error);
        }
    });

}
export function getErrorMessage(err, defaultMessage = false) {
    if (err.response) {
        return err.response.data.message;
    } else if (err.clientMessage) {
        return err.clientMessage;
    } else {
        return defaultMessage ? defaultMessage : 'errorSomethingWrong';
    }
};
export function getErrorCode(err) {
    if (err.response) {
        return err.response.data.errorCode;
    } else {
        return '';
    }
};




export function displayMessage(params) {
    return (dispatch, getState) => {
         params['getState'] = getState;

        _displayMessage(params);
    }
}
  function _displayMessage(params) {
     var message = '';
     // console.log('params', params,config.clientMessages[params.messageCode] );
     if (!_.isUndefined(config.clientMessages[params.messageCode])) {
         if (!_.isUndefined(params['messageData']) && _.size(params['messageData']) >0) {
            var re = new RegExp(Object.keys(params['messageData']).join("|"), "gi");
            message = config.clientMessages[params.messageCode].replace(re, function (matched) {
                return params['messageData'][matched];
            });
        }else{
            message = config.clientMessages[params.messageCode].replace(/\s?#\d*#\s?/g, '');
        }
    } else if(!_.isUndefined(params.messageCode)) {
        /* from server or other messages */
        message = params.messageCode;
    }
console.log('message', message );
    var notification = params.getState().appDataReducer.notification;
    // console.log('notification',notification );
      if (notification) {
        notification.addNotification({
            message: message,
            level: params['messageType'] || 'success',
            autoDismiss: 15,
            position: 'br'
        });
    }

}













