 import {USER_LOGOUT,
    ACCOUNT_FETCH_ORDERS,ACCOUNT_UPDATE_ORDER, ACCOUNT_ADD_ORDER }  from "actions/actionTypes";

var _ = require('lodash');

const initialState = {fetched:false,  data: [], total: 0, selectedRecords:[]};
export default function (state = initialState, action) {
    switch (action.type) {
        case ACCOUNT_FETCH_ORDERS:
          console.log('ACCOUNT_FETCH_ORDERS', action, action.payload);
            return {
                ...state,
                data: action.payload.data,
                total: action.payload.total,
                fetched: true,
            }
        case ACCOUNT_ADD_ORDER:
            console.log('ACCOUNT_ADD_ORDER', state, action.payload);
            return {
                ...state,
                data: _.concat(state.data, [action.payload]),
                total:state.total+1
            }
        case ACCOUNT_UPDATE_ORDER:
            console.log('ACCOUNT_UPDATE_ORDER', state, action.payload);
            var itemIndex = _.findIndex(state.data, {'_id': action.payload._id});

            var updatedRecords = [...state.data.slice(0, itemIndex),
                action.payload,
                ...state.data.slice(itemIndex + 1)];
            return {
                ...state,
                data: updatedRecords
            }
        case USER_LOGOUT:
              return initialState;
        default:
            return state;
    }
}

 export const getRecord = (state, id) => {
     return state.accountOrdersReducer.data.filter(record => record._id === id)[0];
 }