 import {
     UPDATE_ACCOUNT_DATA,USER_LOGOUT }  from "actions/actionTypes";

var _ = require('lodash');

const initialState = {  fetched:false, agentData:{}, accountData: [], accountMembers: [] };
export default function (state = initialState, action) {
    switch (action.type) {
        case UPDATE_ACCOUNT_DATA:
          console.log('UPDATE_ACCOUNT_DATA', action, action.payload);
            return {
                ...state,
                agentData: action.payload.agentData? action.payload.agentData : state.agentData,
                  accountData: action.payload.accountData? action.payload.accountData : state.accountData,
                accountMembers: action.payload.accountMembers? action.payload.accountMembers : state.accountMembers,
                fetched: true,
            }
        case USER_LOGOUT:
            console.log('in USER_LOGOUT ' );
            return initialState;

        default:
            return state;
    }
}
