var moment = require('moment');

module.exports = {
    appVersion:117.36,
    getEnvironment: function (env=false) {
         if (env){
            return this.environments[env]
        } else {
            return this.environments[process.env.NODE_ENV]
        }
    },
    getEnvironmentName: function () {
        return process.env.NODE_ENV
    },
    environments:{
        production: {
         //   serverBaseURL:'http://localhost:3038',
         //   serverFrontEndURL:'http://localhost:8080',
          serverBaseURL:'https://rmdvalet.com',
           serverFrontEndURL:'https://rmdvalet.com',
            WorkWaveAPIKey:'9cd8297c-9bf4-4bb2-9b4d-54d7c89a9f20',
            Stripe:{
                publishableAPIKey:'pk_live_tCQy5e0Sifhn74q4chwvIgBi',
            },
            AdministratorAccountID: 'FfawekpmQaajIx4gwNVGrQ==',
            csvParsingDelimiter:',',
            csvParsingDelimiterData:';',
            ignoreTokenValidation:false,
        },
        development: {
      serverBaseURL:'https://rmdtest.azurewebsites.net',
          serverFrontEndURL:'https://rmdtest.azurewebsites.net',
        //  serverBaseURL:'http://localhost:3038',
       //  serverFrontEndURL:'http://localhost:8080',
            WorkWaveAPIKey:'9cd8297c-9bf4-4bb2-9b4d-54d7c89a9f20',
            Stripe:{
                publishableAPIKey:'pk_test_MluOkiv01JGIL0joXBuq8KyD'
            },
            AdministratorAccountID: 'FfawekpmQaajGh4gwNVGrQ==',
            csvParsingDelimiter:',',
            csvParsingDelimiterData:';',
            ignoreTokenValidation:false,
        },
        local:{
            serverBaseURL:'http://localhost:3038',
            serverFrontEndURL:'http://localhost:8080',
            WorkWaveAPIKey:'9cd8297c-9bf4-4bb2-9b4d-54d7c89a9f20',
            Stripe:{
                publishableAPIKey:'pk_test_MluOkiv01JGIL0joXBuq8KyD'
            },
       // AdministratorAccountID:  'FfawekpmQaajGh4gwNVGrQ==',
       AdministratorAccountID: 'FfawekpmQaajIx4gwNVGrQ==', /* production */
            // csvParsingDelimiter:',',
            // csvParsingDelimiterData:';',
            csvParsingDelimiter:';',
            csvParsingDelimiterData:',',
        //    csvParsingDelimiter:',', /* development */
           // csvParsingDelimiterData:';',/* development */
            ignoreTokenValidation:false,
         },
    },
    templateEngine: 'handlebars',
    pageLink1:'https://www.youtube.com/watch?v=sQ2-l7kvWN4',
    LinkedIn:{
       // clientID:'77tcxjyyxrdlkz'
       clientID:'78ku556lwlirlb',
    },
    Google:{
      //  GooglePlusClientID:'847076346998-3v1mgrr7uhe0jsffmd10guc525aedl2k.apps.googleusercontent.com',
        GooglePlusClientID: '168762344683-djp535crpjc9mk5o63pbu8nfuvc4t87q.apps.googleusercontent.com'
    },
    table: {
        numberPerPage: 10,
        maxNumberPerPage: 100
    },
     defaultCoordinates:{
     //   lat: 39.72907597237074,
      //  lng:  -104.98187083782142
         lat:  39.64589863653027,
         lng:  -104.9994763090071

     },
    maps:{
        defaultPolygonCoordinates:[
            {lat: 39.759331595572036, lng:-104.97397899627686},
            {lat: 39.74197633162923, lng:-104.97389316558838},
            {lat: 39.74194333266686, lng: -104.96007442474365},
            {lat: 39.75936458620625, lng:-104.96007442474365}
        ],
   //   defaultPano:'KPnn5H-ibW7ktLkMI4KNQQ',
  //  defaultPov:{heading: 182.11557268599168, pitch:  6.554578273708572, zoom: 1},
 //  defaultPhotographerPov:{heading: 269.8016357421875, pitch: 1.97406005859375},
       defaultPano:'XNjVma0tWWmJ4tvLJ7gvCg',


      defaultPov:  {heading: 88.789319877817576, pitch: -1.843822543610614,   zoom: 0.25},
        defaultPhotographerPov:{heading: 200, pitch: -5.3887252807617188},

        defaultZoom:10,
        defaultBounds:{
            north: 39.75,
            south: 39.69,
            east: -105.04,
            west: -104.95
        }
    },
    batchActionsList:[{label:'Set Processing orders to Pending', value:'SetProcessingOrdersAsPending'} ],
    inventoryTypes:[
        {value:"Box", label:'Box',category:'Accessory'},
        {value:"Yardarm", label:'Yardarm', category:'DisplayMethod'},
        {value:"Purchased", label:'Purchased',category:'Accessory'},
        {value:"Panel", label:'Panel',category:'Panel'},
        {value:"Rider", label:'Rider',category:'Accessory'},
        {value:"Frame", label:'Frame',category:'DisplayMethod'},
    ],
    additionalInventoryTypeProperties: {
        'Yardarm': [{label: 'Wooden Yardarm', display:true, value: 'Wooden', 'type':'type'},
            {label: 'Aluminium Yardarm (customer)', display:true, value: 'Aluminium','type':'type'},
            {label: 'Wooden Yardarm (customer)', display:true, value: 'WoodenYardarmCustomer','type':'type'},
            {label: 'Not a custom managed serial', display:false,  value: 'NotManagedByCustomer','type':'property'} ],



        'Box': [{label: 'Brochure Box (rented)',display:true,  value: 'Brochure','type':'type'},{label: 'Not a custom managed serial', display:false, value: 'NotManagedByCustomer','type':'property'} ],
    },
    CardExpiryMonthOptions:[
        {value:"01", label:'01' },
        {value:"02", label:'02' },
        {value:"03", label:'03' },
        {value:"04", label:'04' },
        {value:"05", label:'05' },
        {value:"06", label:'06' },
        {value:"07", label:'07' },
        {value:"08", label:'08' },
        {value:"09", label:'09' },
        {value:"10", label:'10' },
        {value:"11", label:'11' },
        {value:"12", label:'12' },
    ],
    CardExpiryYearOptions: function(){
        var options = []
        for (var i = 1; i < 20; i++) {
            options.push({value: moment().add(i,'years').format('Y'), label: moment().add(i,'years').format('Y')})
        }
        return options
    },
   feeTypesMap:{'Order Fee':'ValetFee', 'Rental Fee':'RentalFee',
       'Zone Upcharge' : 'ValetZoneFee',    'Purchased Inventory':'PurchasedInventoryAmount'},
    daysOfWeekMap:{
        1:'Sunday',
        2:'Monday',
        3:'Tuesday',
        4:'Wednesday',
        5:'Thurdsay',
        6:'Friday',
        7:'Saturday'
    },
    apiErrorCodeMap:{
        10: 'UserNotFoundOnSignIn',
        11: 'UserAlreadyExistsOnSignUp',
        12:'AccessTokenNotValid',
        13:'errorPasswordNotCorrect',
        14:'TokenExpired',
    },
    // routePermissionsMap:{
    //     '/cust/valet': {
    //         rolesAllowed:['Agent', 'SuperAdministrator', 'AccountAdministrator'],
    //         redirect:'/account',
    //         messageCode:'errorAccessCustomerAdministrator'
    //     }
    // },
    accountRoles  : [
        {label: 'Account Administrator', value:'AccountAdministrator'},
        {label: 'Agent', value:'Agent'}
    ],
    inventorySetupOptionToTextMap : {
        inventorySent:'Inventory has been sent to RMD, waiting for it to be available in the Valet App',
        inventoryAtCustomerLocation: 'Inventory is at my location',
        inventoryToBeProduced:'Waiting for inventory to be produced'
    },
    accountBillingTypesList:['invoiced','pay_as_you_go'],
    invoiceTypesList: ['Errand', 'Install', 'Remove', 'Modify', 'Inventory', 'InventoryStorageFee', 'ExtendedRentalInventory'],
    bootstrapTableOptions : {
        page: 1,  // which page you want to show as default
        sizePerPage: 10,  // which size per page you want to locate as default
        paginationSize: 5,  // the pagination bar size.
        prePage: 'Prev', // Previous page button text
        nextPage: 'Next', // Next page button text
        firstPage: 'First', // First page button text
        lastPage: 'Last' // Last page button text,
    },
    userRoles:["User", "Administrator"],

    clientMessages: { /* messages used on client side */
        recordDeleted: "#1# deleted",
        recordUpdated: "#1# updated",
        recordAdded: "#1# added",
        recordSaved: "#1# saved",
        recordsUpdated:"#1# have been updated",
        errorSomethingWrong: "Something went wrong",
        errorFieldRequired : "#1# is a required field",
        errorNotANumber:  "#1# is not a valid number",
        errorValueIsNotValid: "#1# is not valid",
        errorFieldsRequired : "Please, specify required fields",
        errorNotUnique : "#1# is not unique",
        errorAccountAcceptTermsOfService:"Please, indicate that you agree to the Terms of Service",
        errorConnection:"Internet connection or server is not available",
        userSignedUp: "You have successfully signed up",
        emailConfirmed: "Your email has been confirmed",
        confirmationEmailSent : "Confirmation email has been sent",
        noRecordsFoundForCriteria : "No records found",
        noProfessionalsUsersFoundForCriteria: "No professionals and users found",
        noAdminAccountsFoundForCriteria: "No Admin Accounts found",
        errorAgentSearchNumberAtLeast: "Please, specify at least #1# symbols for a search string",
        noCustomersFoundForCriteria: "No Customers found",
        noInventoryFoundForCriteria: "No inventories found",
        noDamagedInventoryFoundForCriteria: "No damaged inventories found",
         invoicesProcessed:"#1# been updated",
        errorAtLeastOneRequired: "Please, add at least one #1#",
        errorAtLeastOneInventoryInPackage: "Please, add at least one item to the  #1#",
        errorAgentEmailNotValid: "Please, specify valid email #1",
        errorAgentRolesNotSpecified:"Please, specify at least one role for #1",
        errorAccessCustomerAdministrator :"Only agency administrator can access this page",
        errorAccessProfessional :"Only professionals can access this page",
        errorAccessAccountMember:"Only agency members can access this page",
        errorAccessAdministratorRoles:"Users with administrator roles can not access this page",
        errorAccessAdministrator:"Only administrators can access this page",
        errorAccessSuperAdministrator:"Only users with 'Super Administrator' role can access this page",
        errorAccountCCDetailsNotSet : "Sorry, can not access this page as account credit card details are not yet added",
        errorAccountCCNotActive : "Sorry, can not access this page as account credit card is not active.",
        errorOrderCanNotBeCompleted: "Only orders in Processing status can be completed",
        errorNoAvailableSerialFound:'Sorry, no available serials found for inventory #1#',
        errorAccessDriver:"Only administrators or drivers can access this page",
        emailWithResetPasswordLinkSent: "Email with reset password link was sent",
        passwordHasBeenSet : "Password has been #1#",
        errorOrderDescriptionIsRequired: "Please, specify where exactly you want the sign #1#  to be installed",
        errorLocationIsRequired: "Please, specify the location where sign #1# should be installed",
        errorAgentAlreadyConnectedToAccount: "This professional is already connected to another account",
        errorAccountAdminRoleNotSpecified: "Please, assign at least one account administrator",
        errorFieldNotSpecified:"Please, specify #1#",
        errorDateIsNotValid : "Specified date is not valid",
        errorRecordNotFound: "#1# not found",
        errorAccountOrderZipCodeIsRequired:"Zip code for specified address is not found",
        errorOrderNumberOfFlagsNotValid:"The number of flags should be more than 1",
        errorAgentNotSelected:"Please, select listing professional",
        errorNotSelected:"Please, select #1#",
        taxRulesUpdated:"Tax rules has been updated",
        errorDateIsNotAvailable: "The selected date is not available. Please, choose other date.",
        errorAtLeastOneAccountAdministrator: "Agency should have at least one Account Administrator ",
        errorOnlyNotYetProcessedOrdersCanBeDeleted:"Only order that are not yet processed can be deleted",
        errorAccountAlreadyExistForUser:"User is already associated with another account. Please, try to login again",
        timeoutRequestExceeded: "The request timed out",
        errorOnlyPendingOrderCanBeEdited:"Only order in Pending state can be changed",
        errorOneOfTwoFieldsRequired : "Either #1# or #2# is required",
        errorDriverOrdersDataNotSpecified: "Please, set data for all package signs",
        errorDriverOrdersImageNotLoaded: "Please, upload an image for the package signs",
        errorImagesNotUploadedForAllPackages: "Please, upload images for all packages",
        errorUploadImage:"Error occurred during image upload. Please, try upload it again",
        errorFileUpload:"Image not uploaded. Please, check it has a size less than 15 mb",
        driverOrderCompleted: "Order has been completed",
        checkoutCompleted:"Checkout has been successfully completed",
        errorAddressOutsideServiceAreas:"Sorry, this location is outside of available service areas",
        errorOrderNotIncludeAddressData:"Please modify the service address to include address, city, state and zip code",
        errorOrderAddressIsOutOfAvailableValetZones:"Please, select address within available service areas",
        errorShouldBePositiveNumber:"#1# should be greater than 0",
        errorShouldBeNumberInRange: '#1# should be a number between #2# and #3#',
        inventorySerialsReceived:'#1# received',
        /* messages */
         professionalCreditCardNotActive:'Credit card for that user is not active. Would you like to place the order under the administrator credit card?',

    },
};
