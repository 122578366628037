import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import config from  '../../../config';

import {StripeProvider, Elements} from 'react-stripe-elements';

import {
    makeRequest,
    dispatchAction,
    displayMessage
} from 'actions';

import  AccountBillingSettingsPage from 'accounts/settings/billing/AccountBillingSettingsPage';

class AccountBillingDetailSetup extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {
            agentMLSorLicense: '',

        }
    };
    handleSuccessDataSubmit(){
        this.props.history.push('/getstarted/inventory-setup');
     }


    render() {
        console.log('state',this.state  );
        return (
            <div>
                <StripeProvider apiKey={config.getEnvironment().Stripe.publishableAPIKey}>
                    <Elements>
                        <AccountBillingSettingsPage setupAccountFlow={true} {...this.props} handleSuccessDataSubmit={this.handleSuccessDataSubmit.bind(this)}/>
                    </Elements>
                </StripeProvider>
            </div>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        accountDataReducer: state.accountDataReducer,
        User: state.userReducer,
     };
}


export default connect(mapStateToProps, null)(AccountBillingDetailSetup);
