import React, {Component} from 'react'
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {persistStore, getStoredState, purgeStoredState} from "redux-persist";
import configureStore from './configureStore'


import {BrowserRouter} from 'react-router-dom'
import {Route} from 'react-router';
import AppLayout from 'common/AppLayout';
const store = configureStore();
persistState();

function persistState() {
     var persistor = persistStore(store, {
        whitelist: ['userReducer', 'shoppingCartReducer'], blacklist: [
        ] //,storage: localForage
    }, () => {
        ReactDOM.render(

                <Provider store={store}>
                     <BrowserRouter>
                        <Route path="/" component={AppLayout}/>
                     </BrowserRouter>
                </Provider>


            , document.querySelector('.reactContainer'))
    })
}
